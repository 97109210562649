
const validErrorCodes = [
  "no-face-found",
  "size-limit-exceeded",
  "non-neutral-face",
  "pop-action-not-performed",
  "inconsistent-data-extracted",
  "unrecognized-document",
  "unreadable-document",
  "retryable-server-error",
  "non-retryable-server-error",
  "blurred-image",
  "no-image-found",
  "glasses-not-permitted",
  "self-headshot-trusted-headshot-mismatch",
  "self-headshot-pop-headshot-mismatch",
  "pop-headshot-trusted-headshot-mismatch",
  "face-too-close",
  "face-close-to-border",
  "face-cropped",
  "face-occluded",
  "face-too-small",
  "face-angle-too-large",
  "multiple-faces-found",
  "non-live-face",
  "invalid-input",
  "invalid-image",
  "unreadable-image",
  "unsupported-content-type"
]

const fastForwardErrorCodes = [
  "self-headshot-trusted-headshot-mismatch",
  "self-headshot-pop-headshot-mismatch",
  "inconsistent-data-extracted"
]

export const filterFastForwardErrors = (errorCodes = []) => errorCodes.filter(code => fastForwardErrorCodes.includes(code))

export const filterErrorCodes = (errorCodes = []) => errorCodes.filter(code => validErrorCodes.includes(code))

const errorMapper = errorCode => {
  switch (errorCode) {
    case "no-face-found":
      return "We could not detect a face in the uploaded image, please try again";

    case "size-limit-exceeded":
      return "The file was too big, please try again with a file smaller than 6MB";

    case "non-neutral-face":
      return "Remember to not smile when taking a selfie, please try again";

    case "pop-action-not-performed":
      return "We could not detect a smile, please try again";

    case "inconsistent-data-extracted":
      return "We could not extract important data from the document, please try with a clearer image";

    case "unrecognized-document":
      return "We could not recognise the document, please try again with a clearer image of a supported identity document";

    case "unreadable-document":
      return "We could not read the document, please try again with a valid file";

    case "retryable-server-error":
      return "There was a temporary problem, please try again later";

    case "non-retryable-server-error":
      return "Sorry, we are experiencing a problem, please try again later with a new submission";

    case "blurred-image":
      return "The image is blurred, please try again with a sharper image";

    case "no-image-found":
      return "We could not find a suitable image within the submission (e.g. within a PDF)";

    case "glasses-not-permitted":
      return "We detected that you are wearing glasses. If you are, please remove them and try again";

    case "self-headshot-trusted-headshot-mismatch":
      return "We could not successfully match the individual in the selfie and identity document, please try again";

    case "self-headshot-pop-headshot-mismatch":
      return "We could not successfully match the individual in the selfie and the \"selfie with a smile\", please try again";

    case "pop-headshot-trusted-headshot-mismatch":
      return "We could not successfully match the individual in the \"selfie with a smile\" and identity document, please" +
          " try again";

    case "face-too-close":
      return "The face in the image is too close to the camera, move further away and try again";

    case "face-close-to-border":
      return "The face in the image is too close to the edge of the photo, center your face and try again";

    case "face-cropped":
      return "The face in the image is not completely in the frame, ensure your face is centered and try again";

    case "face-occluded":
      return "The face in the image is occluded by something, ensure your whole face is visible in the center of the frame and try again";

    case "face-too-small":
      return "The face in the image is too small, move closer to the camera and try again";

    case "face-angle-too-large":
      return "The angle of the face within the image is too large, try again while looking straight at the camera";

    case "multiple-faces-found":
      return "Multiple faces were detected in picture, ensure that only one person is within the capture frame";

    case "non-live-face":
      return "The face was detected as non live. This could be because it is a picture of a picture or someone wearing a mask";

    case "invalid-input":
      return "The image could not be read, try again with a valid image.";

    case "invalid-image":
      return "The image could not be read, try again with a valid image.";

    case "unreadable-image":
      return "The image could not be read and may be corrupted, try again with a valid image.";

    case "unsupported-content-type":
      return "The submitted file type is not supported, try again with a JPEG, PNG or PDF file";

    case "Network Error":
      return "Looks like there was a network error, please try again";

    default:
      console.log(`Unhandled error code found "${errorCode}"`)
      return "Sorry, we are experiencing a problem, please try again later"
  }
};

export default errorMapper
