import React, {useState} from "react"
import {get} from "lib/get"
import MobileLayout from "components/layout/MobileLayout"
import FlexBox from "components/layout/FlexBox"
import Button from "components/button/Button"
import CheckBox from "components/checkBox/CheckBox"
import TextArea from "components/textArea/TextArea"
import ToggleButton from "components/toggleButton/ToggleButton"
import Input from "components/input/Input"
import Header from "../components/Header"
import saveFeedback from "./saveFeedback"

const isNotEmpty = str => str && str.trim().length > 0

const ResultsContainer = ({history, location}) => {
  const identityVerificationId = get(["state", "identityVerificationId"], location, undefined)
  return (
    <Results identityVerificationId={identityVerificationId} done={(feedback) => {
      const {data} = feedback || {comment: undefined, journey: undefined}
      if (isNotEmpty(data.comment) || isNotEmpty(data.journey)) {
        saveFeedback(feedback)
      }
      history.push("/idv/intro")
    }}/>
  )
}

export default ResultsContainer

const Results = ({done, identityVerificationId}) => {
  return (
    <MobileLayout header={<Header title="All Done" hideSteps={true}/>}>
      <FlexBox style={{textAlign: "center"}} margin column flexGrow>
        <FlexBox centered style={{marginTop: 20, marginBottom: 40}}>
          <b>Thank you,</b> you made it!
        </FlexBox>
        <Feedback {...{done, identityVerificationId}}/>
      </FlexBox>
    </MobileLayout>
  )
}

export const ResultsDemo = () => {
  return (
    <Results identityVerificationId={"test-uuid"} done={(feedback) => {
      saveFeedback(feedback)
    }}/>
  )
}

const Feedback = ({done, identityVerificationId}) => {
  const [email, setEmail] = useState("")
  const [comment, setComment] = useState("")
  const [status, setStatus] = useState("")
  const [noDocuments, setNoDocuments] = useState(false)
  const [tooSlow, setTooSlow] = useState(false)
  const [tooFiddly, setTooFiddly] = useState(false)

  return (
    <FlexBox column flexGrow>
      <h3 style={{marginBottom: 20}}>Please leave us some feedback</h3>
      <h4>Email (optional)</h4>
      <FlexBox margin centered>
        <Input value={email} onChange={(value) => setEmail(value)}/>
      </FlexBox>

      <h4 style={{marginTop: 20}}>How easy was the journey for you?</h4>
      <FlexBox margin centered>
        <ToggleButton style={{minWidth: 70}} selected={status === "easy"} onClick={() => setStatus("easy")}>Easy</ToggleButton>
        <ToggleButton style={{minWidth: 70, marginLeft: 10, marginRight: 10}} selected={status === "average"}  onClick={() => setStatus("average")}>Average</ToggleButton>
        <ToggleButton style={{minWidth: 70}} selected={status === "difficult"}  onClick={() => setStatus("difficult")}>Difficult</ToggleButton>
      </FlexBox>

      {
        status === "difficult" &&
        <FlexBox centered column>
          <FlexBox vMargin>Sorry to hear that, what was the problem?</FlexBox>
          <FlexBox column>
            <CheckBox label="Didn't have the documents" onChanged={(value) => setNoDocuments(value)}/>
            <CheckBox label="Too slow" onChanged={(value) => setTooSlow(value)}/>
            <CheckBox label="Too fiddly" onChanged={(value) => setTooFiddly(value)}/>
          </FlexBox>
        </FlexBox>
      }

      <FlexBox flexGrow vCenter column>
        <h4 style={{marginBottom: 10, marginTop: 10}}>Feel free to leave a comment</h4>
        <TextArea {...{rows: 5}} value={comment} onChange={(e) => {
          setComment(e.target.value);
        }}/>
      </FlexBox>

      <FlexBox hCenter marginTop style={{marginBottom: 30}}>
        <Button onClick={()=> done
          && done({
            source: "sprinthive-demo",
            uuid: identityVerificationId,
            type: "idvId",
            data: {comment, journey: status, noDocuments, tooSlow, tooFiddly, email}
          })}>Start again</Button>
      </FlexBox>
    </FlexBox>
  )
}


