import React, {useRef, useEffect, useState} from "react";
import moment from "moment";
import {useMeasure} from "hooks/useMeasure";
import {TimeChart} from "../timeChart/TimeChart";
import {theme} from "theme/theme";

const Style = {
  container: {},
  selectedItem: {
    paddingTop: 5,
    paddingLeft: 10,
    borderTop: "solid 1px #dfe1e8",
    display: "grid",
    gridRowGap: 5,
    gridColumnGap: 10,
    gridTemplateRows: "15px",
    gridTemplateColumns: "auto auto auto 1fr",
    minHeight: 20,
    fontSize: 12,
    color: theme.fontColor4,
  },
  debit: {
    color: "#8d2b30",
    fontWeight: 600
  },
  credit: {
    color: "#5f9f63",
    fontWeight: 600
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 600,
    textTransform: "uppercase",
    color: "#b7b7b7",
    justifySelf: "end"
  },
  selectedItemInfo: {
    marginTop: 4
  }
};

const PerDayChart = ({transactions, setItemSelected}) => {
  const chartRef = useRef(undefined);
  const {width} = useMeasure(chartRef);
  const margin = {top: 10, right: 0, bottom: 55, left: 50};
  const groupBy = "day";

  const [data, setData] = useState(undefined);
  useEffect(() => {
    const _data = transactions.reduce((acc, t) => {
      const found = acc[t.date];
      if (!found) {
        acc[t.date] = {date: t.date, key: moment(t.date, "YYYY-MM-DD").toDate(), credit: 0, debit: 0}
      }
      if (t.transactionValue > 0) {
        acc[t.date].credit += t.transactionValue;
      } else {
        acc[t.date].debit += (t.transactionValue * -1);
      }
      return acc;
    }, {});

    setData(Object.keys(_data).map(k => {
      _data[k].debit = _data[k].debit.toFixed(2);
      _data[k].credit = _data[k].credit.toFixed(2);
      return _data[k];
    }));
  }, [transactions]);

  return (
    <div className="PerDayChart" ref={chartRef} style={Style.container}>
      {data && data.length > 0 && width > 0
        ? <TimeChart {...{width, height: 200, margin, data, groupBy, setItemSelected}}/>
        : null
      }
    </div>
  )
};

export default PerDayChart
