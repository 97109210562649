import React from "react";
import Radium from "radium";
import {theme} from "../../theme/theme";

const Style = {
  container: {
    backgroundColor: theme.backgroundColor1,
    width: '95%',
    fontSize: '100%',
    color: '#c1c1c1',
    outline: 'none',
  }
};

export default Radium(props => {
  return (
    <textarea style={Style.container} {...props}/>
  )
})
