import axios from "axios";
import {feedbackEndpoint} from "AppConfig";

const saveFeedback = (feedback) => {
  axios.post(feedbackEndpoint, feedback)
    .then(() => console.log("feedback saved"))
    .catch(() => console.error("error saving feedback"))
}

export default saveFeedback
