import React from "react";

const Style = {
  container: {
    width: "100%",
    height: "100%"
  }
};

const Placeholder = () => <div style={Style.container}/>
export default Placeholder
