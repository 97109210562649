import React from "react";
import Radium from "radium";
import {theme} from "../../theme/theme";
import noneIfNull from "../../lib/noneIfNull";

const Style = {
  card: {
    display: "grid",
    gridTemplateColumns: "repeat(1, auto)",
    gridTemplateRows: "repeat(2, auto)",
    minWidth: 300,
    color: theme.fontColor4,
    justifyItems: "start",
    alignItems: "start",
  },
  value: {
    fontSize: 18,
    fontWeight: 600,
    marginTop: 10,
    color: theme.fontColor4,
  },
  large: {
    fontSize: 34,
    fontWeight: 800,
  },
  small: {
    fontSize: 14,
    fontWeight: 600,
  },
  right: {
    justifySelf: "end"
  },
  centered: {
    justifySelf: "center"
  },
};

export default Radium(({title, value, large, small, right, centered}) => {
  return (
    <div style={Style.card}>
      <div style={[
        right && Style.right,
        centered && Style.centered,
      ]}>{title}</div>
      <div style={[
        Style.value,
        large && Style.large,
        small && Style.small,
        right && Style.right,
        centered && Style.centered,
      ]}>{noneIfNull(value)}</div>
    </div>
  )
});
