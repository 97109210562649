import React, {useCallback, useEffect} from "react"
import BannerLayout from "components/layout/BannerLayout"
import Banner from "./Banner"
import {themeStyle} from "theme/theme"
import CaptureImage from "components/captureImage/CaptureImage"
import {List, ListItem} from "components/list/List"
import {dataURItoBlob} from "lib/imageUtils"
import ConfirmImage from "components/captureImage/ConfirmImage"
import {ErrorPage} from "components/error/ErrorPage"
import Loading from "../components/Loading"
import {useUpload} from "hooks/useServerCall"
import {identityEndpoint, useApiKey} from "AppConfig"
import {get} from "lib/get"
import mapErrorCodeToMessage from "./ErrorMapper"
import {useImageData} from "hooks/useImageData"

const Style = {
  container: {
    girdArea: "content",
    display: "grid",
    gridGap: 10,
    marginLeft: 40,
    marginRight: 40,
    marginBottom: 40,
    justifyItems: "center",
    gridTemplateRows: "auto auto auto 1fr",
  },
}

const CaptureSelfHeadshot = ({history, location: {state}}) => {
  const apiKey = useApiKey();
  const {authToken} = state;
  const {identityVerificationId} = state.data;
  const url = `${identityEndpoint}/identityVerification/sync/${identityVerificationId}/selfie`;
  const [imageData, setImageData, reset] = useImageData();
  const [data, loading, error, call, resetError, percentCompleted] = useUpload({authToken, apiKey, url});

  const onRetry = useCallback(() => {
    reset();
    resetError();
  }, [reset, resetError]);

  const onImageAccepted = () => {
    const data = new FormData();
    data.append("file", dataURItoBlob(imageData.base64Str));
    call(data);
  };

  const onRetakeImage = () => {
    reset();
  };

  useEffect(() => {
    if (!loading && data) history.push("/idv/identityVerification/SelfieResults", {data, authToken})
  }, [loading, data, history, authToken]);

  if (loading) {
    const loadingMessage = percentCompleted < 100 ? "Uploading..." : "Processing...";
    return <Loading banner={<Banner selectedIndex={1}/>} loadingMessage={loadingMessage}/>;
  } else if (error) { // the post to the server has responded with an error
    if (data && data.status === "verification_failed") {
      // explain to the user that something out of the ordinary is going on here
      return <ErrorPage message="Looks like there might be a problem, we will take a look..."/>
    } else {
      const errorCodes = get(["response", "data", "selfie", "errorCodes"], error, []);
      if (errorCodes.length > 0) {
        // when we have errorCodes use it to map them to a message worthy of displaying to a user
        return <ErrorPage
          message={mapErrorCodeToMessage(error.response.data.selfie.errorCodes[0])}
          onRetry={onRetry}/>
      } else {
        return <ErrorPage/>
      }
    }
  } else if (!data) {
    return (
      <BannerLayout banner={<Banner selectedIndex={1}/>}>
        <div style={Style.container}>
          <div style={themeStyle.sectionTitle}>Capture a self headshot</div>
          <List>
            <ListItem>
              Take a picture of you and remember to not smile.
            </ListItem>
          </List>
          {
            !imageData.base64Str
              ? <CaptureImage onImageCaptured={({base64Str, width, height}) => setImageData({base64Str, width, height})}/>
              : <ConfirmImage {...{imageData, onImageAccepted, onRetakeImage}}/>
          }
        </div>
      </BannerLayout>
    )
  } else {
    return null;
  }
};

export default CaptureSelfHeadshot
