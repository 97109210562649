import {merge, of} from "rxjs";
import {catchError, map, mergeMap, scan, startWith} from "rxjs/operators";

export const callApi = ({createEvent, namespace, apiFn}) => {
  const [id$, call] = createEvent(namespace);
  const [reset$, reset] = createEvent(`${namespace}Reset`);

  const data$ = id$.pipe(
    mergeMap(id => apiFn(id).pipe(
      mergeMap(payload => {
        if (payload.percentCompleted === undefined || payload.percentCompleted === null) {
          payload.loading = false;
        } else {
          payload.loading = payload.percentCompleted < 100 || !payload.data;
        }
        return of(payload);
      })
    )),
    catchError(error => of({error, loading: false})),
  );

  const loading$ = merge(
    id$.pipe(map(() => ({loading: true}))),
  );

  const resetState$ = reset$.pipe(
    map(() => ({loading: false, error: undefined, data: undefined}))
  );

  const state$ = merge(loading$, data$, reset$, resetState$).pipe(
    startWith({
      loading: false,
      error: undefined,
      data: undefined,
      call,
      reset,
    }),
    scan((state, value) => ({...state, ...value})),
    map(data => ({[namespace]: data})),
  );

  return [state$, reset$]
};

