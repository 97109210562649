import React from "react";
import Radium from "radium";
import {theme} from "../../theme/theme";

const Style = {
  card: {
    display: "grid",
    gridTemplateColumns: "repeat(1, auto)",
    gridTemplateRows: "repeat(2, auto)",
    color: theme.fontColor4,
    fontSize: 12,
    fontWeight: 400,
  },
  value: {
    fontSize: 24,
    fontWeight: 600,
    marginTop: 10,
    color: theme.fontColor4,
  },
  debit: {
    color: "#8d2b30",
    fontWeight: 600
  },
  credit: {
    color: "#5f9f63",
    fontWeight: 600
  },
  right: {
    justifySelf: "end"
  },
  centered: {
    justifySelf: "center"
  },
};


export default Radium(({title, value, large, debit, credit, right, centered}) => {
  return (
    <div style={Style.card}>
      <div>{title}</div>
      <div style={[
        Style.value,
        large && Style.large,
        debit && Style.debit,
        credit && Style.credit,
        right && Style.right,
        centered && Style.centered,
      ]}>{value}</div>
    </div>
  )
});
