import React from "react";
import Button from "../button/Button";

const Style = {
  hideFileInput: {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1
  },
  label: {
    marginTop: 10,
    cursor: "pointer",
  },
};

const FileSelector = ({instanceId = "fileSelector", onChange, disabled, text = "Select file"}) => {
  return (
    <div style={Style.container}>
      <input style={Style.hideFileInput} id={instanceId} type="file" capture="camera"
             onChange={onChange} accept="image/jpeg,.pdf"/>
      <label style={Style.label} htmlFor={instanceId}>
        <Button disabled={disabled}
                text={text}/>
      </label>
    </div>
  )
};

export default FileSelector
