import React from "react";
import BankStatementProvider, {useBankStatementContext} from "../BankStatementContext";
import SelectBankStatement from "./SelectBankStatement";
import CrunchTheNumbers from "../../components/pages/CrunchTheNumbers";
import ViewResults from "./ViewResults";
import {ErrorPage} from "components/error/ErrorPage";

const ViewRouter = () => {
  const {startBatch} = useBankStatementContext();
  const {loading, percentCompleted, data, error} = startBatch;
  if (loading) {
    const loadingMessage = percentCompleted < 100 ? "Uploading..." : "Processing...";
    return <CrunchTheNumbers {...{loadingMessage}}/>
  } else if (error) {
    return <ErrorPage/>
  } else {
    return !data
      ? <SelectBankStatement/>
      : <ViewResults/>
  }
};

const Provider = () => {
  return (
    <BankStatementProvider>
      <ViewRouter/>
    </BankStatementProvider>
  )
}

export default Provider
