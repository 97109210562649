import React from "react"
import Button from "components/button/Button"

const Style = {
  labelStyle: {cursor: "pointer", textAlign: "center"},
  hideFileInput: {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1
  }
}

const CaptureImage = ({instanceId = "captureImageFromMobileOrTablet", disabled, onChange, maxFileSize = 6, onError}) => {
  return (
    <div>
      <input style={Style.hideFileInput} id={instanceId} type="file" capture="camera"
             onChange={e => {
               if (e.target.files && e.target.files[0]) {
                 const file = e.target.files[0];
                 const filesize = ((file.size/1024)/1024).toFixed(4) // MB

                 // to disable the max file size check don't pass an onError function
                 if (onError) {
                   if (filesize <= maxFileSize) {
                     onChange && onChange(file)
                   } else {
                     onError("size-limit-exceeded")
                   }
                 } else {
                   onChange && onChange(file)
                 }

               } else {
                 console.warn("No file found...")
               }
             }}
             accept="image/jpeg"/>
      <label style={Style.labelStyle} htmlFor={instanceId}>
        <Button disabled={disabled}
                text="Open camera"/>
      </label>
    </div>
  )
}

export default CaptureImage
