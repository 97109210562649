import React, {createContext, useContext} from 'react'
import {withEffects} from "refract-rxjs";
import {combineLatest, from, merge, of} from "rxjs";
import {scan, startWith, map, mergeMap, filter} from "rxjs/operators";
import axios from "axios";
import {incomeVerificationEndpoint} from "AppConfig";
import {callApi} from "lib/callApi";
import generateTransactionIds from "lib/generateTransactionIds";
import {uploadProgressHandler} from "lib/axiosUtils";

const BankStatementContext = createContext({});
export const useBankStatementContext = () => useContext(BankStatementContext);

const uploadFiles = (files) => {
  const formData = new FormData();
  files.forEach(f => formData.append("file", f, f.name));
  const [onUploadProgress, uploadProgress$] = uploadProgressHandler();
  const request$ = from(axios.post(`${incomeVerificationEndpoint}/verifyIncome/doc/intelliRead/file`,
    formData, {onUploadProgress}))
    .pipe(
      mergeMap(({data, headers}) => {
        const idToken = headers['x-application-token'];
        generateTransactionIds(data);
        // convert the relative url to an absolute url
        data.pages = data.pages.map(pageUrl => `${incomeVerificationEndpoint}${pageUrl}`);
        return of({data, authToken: {tokenType: "Bearer", idToken}});
      }),
    );
  return merge(uploadProgress$, request$);
};

const filterTransactions = (batchState$, transactionFilter$) => {
  const transaction$ = batchState$.pipe(
    filter(ctx => !ctx.startBatch.loading),
    filter(ctx => ctx.startBatch.data
      && ctx.startBatch.data.extractedData
      && ctx.startBatch.data.extractedData.transactions),
    mergeMap(ctx => of(ctx.startBatch.data.extractedData.transactions)),
  );

  return combineLatest([transaction$, transactionFilter$])
    .pipe(
      map(([transactions, filter]) => {
        if (filter.transactionFilter === "all") {
          return {filteredTransactions: transactions};
        } else {
          const filterCredits = ({transactionValue}) => transactionValue > 0;
          const filterDebits = ({transactionValue}) => transactionValue < 0;
          const filterFn = filter.transactionFilter === "credits" ? filterCredits : filterDebits;
          return {filteredTransactions: transactions.filter(filterFn)}
        }
      })
    );
};

const aperture = component => {
  const children$ = component.observe("children");
  const [batchState$] = callApi({createEvent: component.useEvent, namespace: "startBatch", apiFn: uploadFiles});
  const [transactionFilter$, onTransactionFilterChanged] = component.useEvent("transactionFilter", {transactionFilter: "credits"});
  const filteredTransactions$ = filterTransactions(batchState$, transactionFilter$).pipe(startWith({filteredTransactions: []}));

  const context$ = merge(batchState$, transactionFilter$, filteredTransactions$).pipe(
    startWith({
      onTransactionFilterChanged
    }),
    scan((state, value) => ({...state, ...value})),
  );

  return combineLatest([children$, context$]).pipe(
    map(([children, context]) => {
      return (
        <BankStatementContext.Provider value={context}>
          {children}
        </BankStatementContext.Provider>
      )
    })
  )
};

const BankStatementContextWithEffects = withEffects(aperture)();
export default BankStatementContextWithEffects
