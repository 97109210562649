import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from 'serviceWorker';
import {BrowserRouter as Router} from "react-router-dom";
import 'index.css';
import App from 'App';
import {mobileAndTabletCheck} from "lib/detectmobilebrowser";
import MobileApp from "MobileApp";
import AppConfigContextProvider from "AppConfig";
const isMobile = mobileAndTabletCheck()

ReactDOM.render(
  <Router basename={process.env.PUBLIC_URL}>
    <AppConfigContextProvider>
      {isMobile ? <MobileApp/> : <App/>}
    </AppConfigContextProvider>
  </Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
