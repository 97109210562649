import React, {useRef, useEffect} from "react";
import Radium from "radium";
import {theme} from "theme/theme";

const Style = {
  input: {
    backgroundColor: "transparent",
    border: `solid 1px ${theme.borderColor1}`,
    outline: 'none',
    padding: 5,
    textAlign: 'center',
    maxWidth: 300,
    minWidth: 200,
    fontSize: '100%',
    color: '#c1c1c1',
  },
  large: {
    maxWidth: 400,
    minWidth: 300,
    fontSize: '130%',
  },
  small: {
    maxWidth: 200,
    minWidth: 150,
    fontSize: '80%',
    padding: 5,
  },
  left: {
    textAlign: 'left',
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
};

const ENTER_KEY_CODE = 13;

const Input = props => {
  const ref = useRef(undefined);
  useEffect(() => {
    if (props.autoFocus && ref.current) {
      ref.current.focus()
    }
  });
  return <InputStyled {...props} reference={ref}/>
};

export default Input

const InputStyled = Radium(({
                        onChange,
                        onEnter,
                        value,
                        tabIndex,
                        left,
                        center,
                        placeholder,
                        right,
                        large,
                        small,
                        style,
                        reference
                      }) => {

  return <input style={[Style.input,
    large && Style.large,
    small && Style.small,
    left && Style.left,
    center && Style.center,
    right && Style.right,
    style]}
                ref={reference}
                type="text"
                placeholder={placeholder}
                onKeyUp={e => {
                  if (e.keyCode === ENTER_KEY_CODE) {
                    onEnter && onEnter()
                  }
                }}
                onChange={e => onChange && onChange(e.target.value)}
                value={value}
                tabIndex={tabIndex}/>
});
