import React from "react";
import {Frown, AlertTriangle} from "react-feather";
import MainLayout from "../layout/MainLayout";
import {theme} from "theme/theme";
import Button from "../button/Button";
import FlexBox from "components/layout/FlexBox";

const potentialMessages = [
  {message: "Oops something went wrong..."},
  {message: "Looks like he a hit a tree Jim..."},
];

const potentialIcons = [
  {icon: <Frown size={72} strokeWidth={1}/>},
  {icon: <AlertTriangle size={72} strokeWidth={1}/>},
];

const Style = {
  container: {
    color: theme.fontColor4,
  },
  message: {
    fontSize: 18,
    fontWeight: 400,
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    textAlign: 'center'
  }
};

export const ErrorPage = ({noLayout, ...props}) => {
  return noLayout ? <ErrorPageNoLayout {...props}/> : <ErrorPageWithLayout {...props}/>
};

export const ErrorPageWithLayout = props =>
  <MainLayout>
    <ErrorPageNoLayout {...props}/>
  </MainLayout>

export const ErrorPageNoLayout =
  ({
     message = potentialMessages[Math.floor(Math.random() * potentialMessages.length)].message,
     icon = potentialIcons[Math.floor(Math.random() * potentialIcons.length)].icon,
     onRetry
   }) => {
    return (
      <FlexBox style={Style.container} column centered>
        {icon}
        <FlexBox style={Style.message}>
          {message}
        </FlexBox>
        {onRetry && <Button style={{marginTop: 20}} text="Try again" onClick={onRetry}/>}
      </FlexBox>
    )
  };
