import React from 'react'
import Radium from 'radium'
import {blueBox, greenBox, orangeBox, redBox, yellowBox} from "./BoxStyles";

export const flexStyle = {
  base: {
    display: 'flex'
  },
  flexDirectionColumn: {
    flexDirection: 'column'
  },
  flexWrap: {
    flexWrap: 'wrap'
  },
  centered: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  left: {
    justifyContent: 'flex-start',
  },
  right: {
    justifyContent: 'flex-end',
  },
  spaceBetween: {
    justifyContent: "space-between"
  },
  spaceEvenly: {
    justifyContent: "space-evenly"
  },
  flexItem: {
    flex: 1
  },
  flexGrow: {
    flexGrow: 1
  },
  round: {
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    borderBottomLeftRadius: 50,
  },
  vCenter: {
    alignItems: 'center'
  },
  hCenter: {
    justifyContent: 'center'
  },
  fullscreen: {
    height: "100%"
  },
  margin: {
    marginTop: 10,
    marginRight: 10,
    marginBottom: 10,
    marginLeft: 10,
  },
  vMargin: {
    marginTop: 10,
    marginBottom: 10,
  },
  hMargin: {
    marginLeft: 10,
    marginRight: 10,
  },
  marginLeft: {
    marginLeft: 10,
  },
  marginRight: {
    marginRight: 10,
  },
  marginBottom: {
    marginBottom: 10,
  },
  marginTop: {
    marginTop: 10,
  },
  padding: {
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
  },
  vPadding: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  hPadding: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  lPadding: {
    paddingLeft: 10,
  },
  rPadding: {
    paddingRight: 10,
  },
  tPadding: {
    paddingTop: 10,
  },
  bPadding: {
    paddingBottom: 10,
  },
};

const FlexBox = Radium(({style, reference, column, centered, left, right, wrap, item, flexGrow,
                          margin, vMargin, hMargin, marginBottom, marginTop, marginLeft, marginRight,
                          padding, vPadding, hPadding, tPadding, bPadding, lPadding, rPadding,
                          round, spaceBetween, spaceEvenly, className, children, onClick, vCenter, hCenter, fullscreen,
                          red, blue, green, orange, yellow}) =>
  <div ref={reference} className={className} style={[
    flexStyle.base,
    fullscreen && flexStyle.fullscreen,
    column && flexStyle.flexDirectionColumn,
    centered && flexStyle.centered,
    left && flexStyle.left,
    right && flexStyle.right,
    wrap && flexStyle.flexWrap,
    item && flexStyle.flexItem,
    flexGrow && flexStyle.flexGrow,
    margin && flexStyle.margin,
    vMargin && flexStyle.vMargin,
    hMargin && flexStyle.hMargin,
    marginLeft && flexStyle.marginLeft,
    marginRight && flexStyle.marginRight,
    marginBottom && flexStyle.marginBottom,
    marginTop && flexStyle.marginTop,
    round && flexStyle.round,
    spaceBetween && flexStyle.spaceBetween,
    spaceEvenly && flexStyle.spaceEvenly,
    vCenter && flexStyle.vCenter,
    hCenter && flexStyle.hCenter,
    padding && flexStyle.padding,
    vPadding && flexStyle.vPadding,
    hPadding && flexStyle.hPadding,
    tPadding && flexStyle.tPadding,
    bPadding && flexStyle.bPadding,
    lPadding && flexStyle.lPadding,
    rPadding && flexStyle.rPadding,
    red && redBox,
    blue && blueBox,
    green && greenBox,
    orange && orangeBox,
    yellow && yellowBox,
    style
  ]}
       onClick={onClick}
  >
    {children}
  </div>
);

export default FlexBox;
