import React from "react";
import {ErrorPage} from "components/error/ErrorPage";
import PayslipContextProvider, {usePayslipContext} from "../PayslipContext";
import SelectPayslip from "./SelectPayslip";
import CrunchTheNumbers from "../../components/pages/CrunchTheNumbers";
import ViewResults from "./ViewResults";

const Payslip = () => {
  const {startBatch} = usePayslipContext();
  const {loading, percentCompleted, data, error} = startBatch;

  if (loading) {
    const loadingMessage = percentCompleted < 100 ? "Uploading..." : "Processing...";
    return <CrunchTheNumbers {...{loadingMessage}}/>
  } else if (error) {
    return <ErrorPage/>;
  } else {
    return !data
      ? <SelectPayslip/>
      : <ViewResults/>
  }
};

const PayslipContainer = () => {
  return (
    <PayslipContextProvider>
      <Payslip/>
    </PayslipContextProvider>
  )
}

export default PayslipContainer
