import React from "react";
import FlexBox from "../../../../components/layout/FlexBox";

const Header = ({title, hideSteps = false, step = 1, totalSteps = 3}) => {
  return (
    <FlexBox column vCenter>
      <h1>{title}</h1>
      { !hideSteps && <h6>Step {step} of {totalSteps}</h6>}
    </FlexBox>
  )
}

export default Header
