import React from "react";
import {theme} from "theme/theme";
import logo from "./sprintHive-logo-white.svg";
import heartPic from "./heart.png";
import {withRouter} from "react-router";
import ConnectButton from "components/button/ConnectButton"
import FlexBox from "components/layout/FlexBox"

const Style = {
  container: {
    display: "grid",
    minHeight: "100vh",
    color: theme.fontColor4,
    backgroundColor: theme.backgroundColor2,
    gridTemplateRows: "auto 1fr auto",
    gridTemplateAreas: `
      "header"
      "content"
      "footer"
    `,
  },
  header: {
    gridArea: "header",
  },
  logoContainer: {
    gridArea: "logo",
    paddingLeft: 30,
  },
  logo: {
    background: `url(${logo})`,
    height: 40,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    cursor: "pointer",
    marginLeft: 30,
    minWidth: 190,
  },
  content: {
    gridArea: "content",
    marginTop: 20,
    marginBottom: 20,
  },
  footer: {
    gridArea: "footer",
    backgroundColor: theme.backgroundColor4,
    display: "grid",
    gridTemplateColumns: "1fr",
    justifyItems: "center",
    color: theme.fontColor4,
    fontSize: 12,
    padding: 60,
  },
  link: {
    fontSize: 14,
    color: theme.linkColor,
    textDecoration: "none",
    fontWeight: 400,
  },
  navItem: {
    cursor: "pointer",
    fontSize: 14,
    fontWeight: 400,
    color: theme.fontColor4,
    marginRight: 30,
    display: "grid",
    gridGap: 5,
    gridTemplateColumns: "auto auto",
    alignItems: "center",
  },
  navCallout: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: theme.fontColor5,
    borderRadius: 50,
    color: theme.fontColor1,
    display: "grid",
    alignItems: "center",
    justifyItems: "center",
  },
  navItemSelected: {
    color: theme.linkColor
  }
};

const Logo = ({history}) => <div style={Style.logo} onClick={() => history.push("/")}/>;

const Layout = withRouter(({history, children}) => {
  return (
    <div style={Style.container}>
      <div style={Style.header}>
        <FlexBox spaceBetween>
          <FlexBox flexGrow vCenter>
            <Logo history={history}/>
          </FlexBox>
          <FlexBox padding>
            <ConnectButton/>
          </FlexBox>
        </FlexBox>
      </div>
      <div style={Style.content}>
        {children}
      </div>
      <div style={Style.footer}>
        <div>
          Made with&nbsp;<img src={heartPic} alt="withLove"/>&nbsp;in Cape Town South Africa by&nbsp;
          <a style={Style.link} href="https://www.sprinthive.com">Sprint<span style={{fontWeight: 800}}>Hive</span></a>
        </div>
      </div>
    </div>
  )
});

export default Layout;
