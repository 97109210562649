import React, {useEffect, useReducer, useState} from "react";
import {interval} from "rxjs";
import {map} from "rxjs/operators";
import {takeUntil, repeat} from "rxjs/operators";
import {theme} from "theme/theme";

const Donut = ({count, percentComplete}) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 42 42" className="donut">
      <circle className="donut-hole"
              cx="21"
              cy="21"
              r="15.91549430918954"
              fill={theme.backgroundColor2}/>
      <circle className="donut-ring"
              cx="21"
              cy="21"
              r="15.91549430918954"
              fill="transparent"
              stroke={theme.backgroundColor1}
              strokeWidth="3"/>

      <circle className="donut-segment"
              cx="21"
              cy="21"
              r="15.91549430918954"
              fill="transparent"
              stroke={theme.linkColor}
              strokeWidth="3"
              strokeDasharray={`${percentComplete} ${(100 - percentComplete)}`}
              strokeDashoffset="25"/>

      <text className="text"
            textAnchor="middle"
            fontSize={12}
            fill={theme.fontColor4}
            fontWeight={600}
            x="50%"
            y="60%">
        {count}
      </text>
    </svg>
  )
};

const useTimeCounter = (onResetCounter$) => {
  const [percentage, setPercentage] = useState(0);
  const [counter, updateCounter] = useReducer((prevState, action) => {
    if (action === "inc") {
      return prevState + 1
    } else if (action === "reset") {
      return 0
    } else {
      return prevState;
    }
  }, 0);
  useEffect(() => {
    const refreshRate = 50;
    const duration = 1000;
    const totalIntervals = duration / 50;
    const timer$ = interval(refreshRate).pipe(
      map(counter => counter + 1),
      map(counter => counter / totalIntervals * 100),
      takeUntil(interval(duration + refreshRate)),
      repeat()
    );

    const subscriptions = [];
    subscriptions.push(timer$
      .subscribe(percentage => {
        setPercentage(percentage);
        percentage === 100 && updateCounter("inc");
      }));

    if (onResetCounter$) {
      subscriptions.push(onResetCounter$.subscribe(() => updateCounter("reset")));
    }

    return () => {
      subscriptions.forEach(s => s.unsubscribe());
    };
  }, [onResetCounter$]);
  return [percentage, counter];
};

const LoadingCounter = ({onResetCounter$}) => {
  const [percentage, counter] = useTimeCounter(onResetCounter$);
  return <Donut count={counter} percentComplete={percentage}/>
}

export default LoadingCounter
