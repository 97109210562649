import React, {useState, useEffect, useRef} from "react";
import {BarChart} from "../barChart/BarChart";
import {mapToDayOfWeek} from "../Utils";
import moment from "moment";
import Placeholder from "../../Placeholder";

export const DayOfWeekChart = React.memo((props) => {
  const {data} = props;
  const chartRef = useRef(undefined);
  const margin = {top: 10, right: 0, bottom: 25, left: 50};
  return (
    <div className="DayOfWeekChart" ref={chartRef}>
      {data && data.length > 0
        ? <BarChart data={data}
                    width={400}
                    height={200}
                    margin={margin}
                    mapXAxisToLabel={mapToDayOfWeek}/>
        : null
      }
    </div>
  )
});

const useTransactionTransformer = (transactions) => {
  const [data, setData] = useState(undefined);

  useEffect(() => {
    const dataMap = {};
    for (let i = 1; i <= 7; i++) {
      dataMap[i] = {key: i, debit: 0, credit: 0}
    }
    transactions.forEach(t => {
      if (t.transactionValue > 0) {
        dataMap[moment(t.date, "YYYY-MM-DD").isoWeekday()].credit += t.transactionValue;
      } else {
        dataMap[moment(t.date, "YYYY-MM-DD").isoWeekday()].debit += (t.transactionValue * -1);
      }
    });

    setData(Object.keys(dataMap).map(k => dataMap[k]));
  }, [transactions]);
  return data;
};

const DayOfWeekChartContainer = (props) => {
  const {transactions} = props;
  const data = useTransactionTransformer(transactions);
  return data ? <DayOfWeekChart {...{data}}/> : <Placeholder/>;
}

export default DayOfWeekChartContainer
