import React, {useState, useCallback} from "react";
import Radium from "radium";
import {theme} from "theme/theme";
import SecureImage from "components/secureImage/SecureImage";

const Style = {
  container: {
    display: "grid",
    gridGap: 5,
    justifyItems: "center"
  },
  imageContainer: {
    position: "relative",
    cursor: "pointer",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.2)",
    width: "100%",
    height: "100%",
    opacity: 0,
    transition: "opacity .5s"
  },
  lightBox: {
    position: "absolute",
    display: "none",
    justifyItems: "center",
    alignItems: "center",
    left: 0,
    top: 0,
    opacity: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    minWidth: "100%",
    minHeight: "100%",
    paddingTop: 30,
    paddingBottom: 30,
    cursor: "pointer",
    transition: "opacity .6s"
  },
  showLightBox: {
    display: "grid",
    opacity: 1,
  }
};

const Image = ({imageUrl}) => <img width={200} src={imageUrl} alt=""/>;

const Overlay = Radium(({opacity = 0}) => {
  return <div style={[
    Style.overlay,
    {opacity}
  ]}/>
});

const mergeStyles = (styles) => Object.assign({}, ...styles);

const LightBox = ({authToken, imageUrl, onHide}) => {
  return <div className="lightBox"
              style={mergeStyles([
                Style.lightBox,
                imageUrl && Style.showLightBox,
                {paddingTop: window.scrollY + 30},
              ])}
              onClick={() => {
                onHide();
              }}>
    <div>
      {
        authToken
          ? <SecureImage {...{authToken, imageUrl, width: 1024}}/>
          : <img width={1024} src={imageUrl} alt="A bank statement or payslip"/>
      }
      <div style={{fontSize: 28, color: theme.fontColor4}} className="glyphicons glyphicons-remove"/>
    </div>
  </div>
};

const ImageWithOverlay = ({imageUrl, onClick, authToken}) => {
  const [opacity, setOpacity] = useState(0);
  const [downloadedImageUrl, setDownloadedImage] = useState(undefined);
  const onImageDownloaded = useCallback((url) => setDownloadedImage(url), []);
  const onImageSelected = useCallback(() => onClick(downloadedImageUrl || imageUrl),
    [downloadedImageUrl, imageUrl, onClick]);

  return (
    <div className="imageContainer" style={Style.imageContainer}
         onMouseOver={() => setOpacity(1)}
         onMouseOut={() => setOpacity(0)}
         onClick={onImageSelected}>
      {authToken
        ? <SecureImage {...{authToken, imageUrl, width: 200, onImageDownloaded}}/>
        : <Image imageUrl={imageUrl}/>}
      <Overlay opacity={opacity}/>
    </div>
  )
};

const ImageWithOverlayContainer = ({authToken, imageUrls}) => {
  const [selectedImage, setSelectedImage] = useState(undefined);
  const onImageSelected = useCallback((url) => setSelectedImage(url), []);

  return (
    <div className="thumbnailViewer" style={Style.container}>
      {
        imageUrls && imageUrls.map((imageUrl, i) =>
          <ImageWithOverlay key={`${i}-imageWithOverlay`} authToken={authToken} imageUrl={imageUrl}
                            onClick={onImageSelected}/>)
      }

      <LightBox authToken={authToken} imageUrl={selectedImage} onHide={() => setSelectedImage(undefined)}/>
    </div>
  )
}

export default ImageWithOverlayContainer
