import React from "react";
import PropTypes from "prop-types";
import InfoCard from "./InfoCard";
import FlexBox from "../layout/FlexBox";
import {theme} from "../../theme/theme";

const payslipStyle = {
  cardBody: {
    display: "grid",
    gridGap: 5,
    fontSize: 14
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 800,
    textTransform: "uppercase",
    marginTop: 5,
    color: theme.fontColor4,
  },
  subDetail: {
    fontSize: 14,
    marginTop: 5
  },
  income: {
    color: theme.fontColor4,
    fontWeight: 800,
    fontSize: 36
  },
};

const PayslipSummary = (props) => {
  const {extractedData, metaData, markers} = props;
  return (
    <FlexBox>
      <InfoCard title="Employee details">
        <div style={payslipStyle.cardBody}>
          <div>Name: {extractedData.name ? extractedData.name : "none"}</div>
          <div>Address: {extractedData.address ? extractedData.address : "none"}</div>
          <div>Identity number: {extractedData.idNumber ? extractedData.idNumber : "none"}</div>
        </div>
      </InfoCard>
      <InfoCard title="Payslip details">
        <div style={payslipStyle.cardBody}>
          <div>Payslip date: {extractedData.payslipDate ? extractedData.payslipDate : "none"}</div>
          <div>Account number: {extractedData.accountNumber ? extractedData.accountNumber : "none"}</div>
          <div>Gross salary: {extractedData.grossIncome ? extractedData.grossIncome : "none"}</div>
          <div>Nett salary: {extractedData.nettIncome ? extractedData.nettIncome : "none"}</div>
        </div>
      </InfoCard>
      <InfoCard title="Income">
        <div style={payslipStyle.income}>{extractedData.nettIncome ? extractedData.nettIncome : "none"}</div>
        <div style={payslipStyle.subTitle}>Scan Type</div>
        <div style={payslipStyle.subDetail}>{metaData.scannerType}</div>
      </InfoCard>
      { // hide the markers if we don't have any
        markers
          ? <InfoCard title="Markers">
            <div style={payslipStyle.cardBody}>
              {
                markers.map(m => <div key={`marker-${m.name}`}>{m.name}: {m.value ? m.value : "none"}</div>)
              }
            </div>
          </InfoCard>
          : null
      }
    </FlexBox>

  )
};

PayslipSummary.propTypes = {
  extractedData: PropTypes.object.isRequired,
  markers: PropTypes.array,
};

export default PayslipSummary;