import React from "react";
import DayOfWeekChart from "../../../../components/charts/dayOfWeekChart/DayOfWeekChart";
import {useBankStatementContext} from "../../bankstatement/BankStatementContext";

const DayOfWeekChartContainer = (props) => {
  const {startBatch} = useBankStatementContext();
  const {transactions} = props.extractedData || startBatch.data.extractedData;

  return <DayOfWeekChart {...{transactions}}/>;
}

export default DayOfWeekChartContainer
