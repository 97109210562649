import React, {useState, useCallback, useEffect} from "react"
import BannerLayout from "../../../components/layout/BannerLayout"
import Banner from "./Banner"
import {themeStyle} from "theme/theme"
import CaptureImage from "components/captureImage/CaptureImage"
import {List, ListItem} from "components/list/List"
import {dataURItoBlob} from "lib/imageUtils"
import ConfirmImage from "components/captureImage/ConfirmImage"
import {ErrorPage} from "components/error/ErrorPage"
import Loading from "../components/Loading"
import {identityEndpoint, useApiKey} from "AppConfig"
import {useUpload} from "hooks/useServerCall"
import mapErrorCodeToMessage from "./ErrorMapper"
import {get} from "lib/get"

const Style = {
  container: {
    girdArea: "content",
    display: "grid",
    gridGap: 10,
    marginLeft: 40,
    marginRight: 40,
    marginBottom: 40,
    justifyItems: "center",
    gridTemplateRows: "auto auto auto 1fr",
  },
}

const initialState = {base64Str: undefined, width: undefined, height: undefined};
const CaptureProofOfPresence = ({history, location: {state}}) => {
  const apiKey = useApiKey();
  const {authToken} = state;
  const {identityVerificationId} = state.data;
  const url = `${identityEndpoint}/identityVerification/sync/${identityVerificationId}/proofOfPresence`;
  const [imageData, setImageData] = useState(initialState);
  const [data, loading, error, call, resetError, percentCompleted] = useUpload({authToken, apiKey, url});

  const onRetry = useCallback(() => {
    setImageData(initialState);
    resetError();
  }, [setImageData, resetError]);

  const onImageAccepted = () => {
    const data = new FormData();
    data.append("file", dataURItoBlob(imageData.base64Str));
    call(data);
  };

  useEffect(() => {
    if (!loading && data) history.push("/idv/identityVerification/popResults", {data, authToken});
  }, [loading, data, history, authToken]);

  const onRetakeImage = () => {
    setImageData(initialState);
  };

  if (loading) {
    const loadingMessage = percentCompleted < 100 ? "Uploading..." : "Processing...";
    return <Loading banner={<Banner selectedIndex={2}/>} loadingMessage={loadingMessage}/>
  } else if (error) {
    if (data && data.status === "verification_failed") {
      // explain to the user that something out of the ordinary is going on here
      return <ErrorPage message="Looks like there might be a problem, we will take a look..."/>
    } else {
      const errorCodes = get(["response", "data", "proofOfPresence", "errorCodes"], error, []);
      if (errorCodes.length > 0) {
        // when we have errorCodes use it to map them to a message worthy of displaying to a user
        return <ErrorPage
          message={mapErrorCodeToMessage(error.response.data.proofOfPresence.errorCodes[0])}
          onRetry={onRetry}/>
      } else {
        return <ErrorPage/>
      }
    }
  } else if (!data) {
    return (
      <BannerLayout banner={<Banner selectedIndex={2}/>}>
        <div style={Style.container}>
          <div style={themeStyle.sectionTitle}>Capture proof of presence</div>
          <List>
            <ListItem>
              Take a picture of you doing a random gesture and we will compare it with
              the other images we have of you.
            </ListItem>
          </List>
          {
            !imageData.base64Str
              ? <CaptureImage onImageCaptured={({base64Str, width, height}) => setImageData({base64Str, width, height})}/>
              : <ConfirmImage {...{imageData, onImageAccepted, onRetakeImage}}/>
          }
        </div>
      </BannerLayout>
    )
  } else {
    return null;
  }
};

export default CaptureProofOfPresence
