import React, {useCallback} from "react";
import Feedback from "components/Feedback";

const FeedbackContainer = ({reset}) => {
  const right = useCallback(() => {
    reset({right: true});
  }, [reset]);
  const wrong = useCallback(() => {
    reset({right: false});
  }, [reset]);

  return <Feedback onRightClicked={right} onWrongClicked={wrong}/>
}

export default FeedbackContainer
