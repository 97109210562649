import React from "react";
import FlexBox from "components/layout/FlexBox";
import Button from "components/button/Button";

const Confirmation = ({message, done}) => {
  return (
    <FlexBox column flexGrow vCenter>
      <FlexBox style={{textAlign: "center"}} margin centered flexGrow>
        {message}
      </FlexBox>
      <Button text="Next" onClick={() => done && done()}/>
    </FlexBox>
  )
}

export default Confirmation
