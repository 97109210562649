import {useState} from "react";

const initialState = {base64Str: undefined, width: undefined, height: undefined};

export const useImageData = () => {
  const [imageData, setImageData] = useState(initialState);
  const reset = () => setImageData(initialState);
  return [imageData, setImageData, reset]
};

