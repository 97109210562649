import React, {useEffect, useState, useRef} from "react";
import {generateHeaders} from "../../lib/axiosUtils";
import axios from "axios";
import {from, of} from "rxjs";
import {filter, mergeMap} from "rxjs/operators";
import HSpinner from "../spinner/HSpinner";

const SecureImage = ({imageUrl, authToken, setImageState, width, onImageDownloaded}) => {
  const imageRef = useRef(null);
  const [downloadedImage, setDownloadedImage] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);

  // when the downloaded image changes let our parent know
  useEffect(() => {
    onImageDownloaded && onImageDownloaded(downloadedImage);
  }, [downloadedImage, onImageDownloaded]);

  useEffect(() => {
    setLoading(true);
    const config = {
      responseType: 'blob',
      headers: generateHeaders(authToken)
    };

    const subscription = of(imageUrl)
      .pipe(
        filter(url => url !== null && url !== undefined && url.length > 0),
        mergeMap(url => from(axios.get(url, config)))
      ).subscribe(({data}) => {
        const reader = new window.FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => {
          const url = reader.result;
          setDownloadedImage(url);
          setLoading(false);
          setError(undefined);
        }
      }, error => {
        setError(error);
        setLoading(false);
        setDownloadedImage(undefined)
      });

    // unsubscribe when we unmount
    return () => subscription.unsubscribe();
  }, [imageUrl, authToken]);

  if (error) return <div>Unable to download image...</div>;

  return loading ? <HSpinner/>
    : <img ref={imageRef}
           width={width}
           src={downloadedImage}
           alt="A bank statement or a payslip"
           onLoad={() => {
             if (imageRef.current) {
               const {width, height} = imageRef.current.getBoundingClientRect();
               setImageState && setImageState({width, height})
             }
           }}
    />

};

export default SecureImage;