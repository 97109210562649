import React from 'react'
import {theme} from "theme/theme";
import MainLayout from "components/layout/MainLayout";
import Banner from "../../components/banner/Banner";
import IncomeResult from "../../components/IncomeResult";
import NameAndAddress from "../../components/NameAndAddress";
import BankDetails from "../../components/BankDetails";
import PerDayChart from "../../components/transactions/PerDayChart";
import Summary from "../../components/transactions/Summary";
import DayOfWeekChart from "../../components/transactions/DayOfWeekChart";
import TransactionList from "../../components/transactions/TransactionList";
import Feedback from "../../components/Feedback";
import ThumbnailViewer from "../../components/pageViewer/ThumbnailViewer";
import TransactionListFilter from "../../components/transactions/TransactionFilter";
import {useBankStatementContext} from "../BankStatementContext";

const Style = {
    container: {
        display: "grid",
        gridTemplateAreas: `
      "banner"
      "main"
    `,
        gridTemplateRows: "auto 1fr"
    },
    main: {
        margin: 20,
        gridArea: "main",
        display: "grid",
        gridGap: 10,
        gridTemplateRows: "auto 1fr",
        color: theme.fontColor4,
        gridTemplateColumns: "auto 1fr 250px",
        gridTemplateAreas: `
      "left    top    right"
      "middle  middle right"
    `,
    },
    left: {
        gridArea: "left",
    },
    top: {
        gridArea: "top",

    },
    right: {
        gridArea: "right",
    },
    middle: {
        gridArea: "middle",
        display: "grid",
        gridGap: 10,
        gridTemplateRows: "220px auto auto 1fr",
        alignItems: "start",
    },
    row: {
        display: "grid",
        gridGap: 10,
        gridTemplateColumns: "1fr 400px",
        gridTemplateRows: "200px"
    }
};

const ViewResults = () => {
    const {startBatch} = useBankStatementContext();
    const {data, authToken, reset} = startBatch;
    const {pages} = data;

    return (
      <MainLayout>
          <div style={Style.container}>
              <Banner selectedIndex={2}/>
              <div style={Style.main}>
                  <div style={Style.left}>
                      <IncomeResult/>
                  </div>

                  <div style={Style.top}>
                      <NameAndAddress/>
                      <BankDetails/>
                  </div>

                  <div style={Style.middle}>
                      <PerDayChart/>
                      <div style={Style.row}>
                          <Summary/>
                          <DayOfWeekChart/>
                      </div>
                      <TransactionListFilter/>
                      <TransactionList/>
                  </div>

                  <div style={Style.right}>
                      <Feedback reset={reset}/>
                      <ThumbnailViewer {...{authToken, imageUrls: pages}}/>
                  </div>
              </div>
          </div>
      </MainLayout>
    )
};

export default ViewResults;
