
// A helper function which ensures all the values are present on the auth token
import {BehaviorSubject} from "rxjs";

const validateAuthToken = authToken => authToken && authToken.tokenType && authToken.idToken;

// A helper function which maps a authToken to an Authorization header.
export const generateHeaders = (authToken, apiKey) => {
  const headers = {}
  if (apiKey) { headers.apikey = apiKey }
  if (validateAuthToken(authToken)) {
    const {tokenType, idToken} = authToken;
    headers['Authorization'] = `${tokenType} ${idToken}`
  }
  return headers
};

// This is a helper function which returns a on progress handler to report the percentage completed of the upload
export const uploadProgressHandler = () => {
  const uploadProgress$ = new BehaviorSubject({percentCompleted: 0});
  const onUploadProgress = progressEvent => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    uploadProgress$.next({percentCompleted});
    if (percentCompleted === 100) {
      uploadProgress$.complete();
    }
  };

  return [onUploadProgress, uploadProgress$]
};
