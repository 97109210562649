import React from "react";
import TransactionList from "../../../../components/transactionList/TransactionList";
import {useBankStatementContext} from "../../bankstatement/BankStatementContext";

const TransactionListContainer = () => {
  const {filteredTransactions: transactions} = useBankStatementContext();
  return (
    <TransactionList {...{transactions}}/>
  )
}

export default TransactionListContainer
