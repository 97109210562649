import React, {useCallback} from "react"
import {theme} from "theme/theme"
import "./services.css"

import absa from "./absa.svg";
import fnb from "./fnb.svg";
import standard from "./standardBank.svg";
import nedbank from "./nedbank.svg";
import capitec from "./capitecbank.svg";
import MainLayout from "../../components/layout/MainLayout";
import FlexBox from "../../components/layout/FlexBox";

const Style = {
  content: {
    gridArea: "content",
    backgroundColor: theme.backgroundColor2,

    display: "grid",
    gridTemplateAreas: `
      "income"
      "document"
      "kyc"
    `,
  },

  income: {
    gridArea: "income",
    paddingLeft: "10vw",
    paddingRight: "10vw",
    display: "grid",
    gridRowGap: 20,
    gridTemplateColumns: "1fr 1fr",
    gridTemplateAreas: `
      "incomeTitle            incomeTitle"
      "bankStatements         payslips"
      "supportedBanks         supportedBanks"
    `,
  },

  incomeTitle: {
    gridArea: "incomeTitle",
    alignSelf: "center",
    justifySelf: "center",
    color: theme.fontColor1,
    fontSize: 40,
    fontWeight: 300,
  },

  bankStatements: {
    gridArea: "bankStatements",
    alignSelf: "stretch",
    justifySelf: "stretch",
    backgroundColor: theme.backgroundColor5,
    marginRight: 10,
    padding: 30,

    display: "grid",
    gridRowGap: 20,
    gridTemplateAreas: `
      "bankTitle"
      "bankOptions"
    `,

  },

  bankTitle: {
    gridArea: "bankTitle",
    color: theme.fontColor1,
    fontSize: 24,
    fontWeight: 300,
  },

  bankOptions: {
    gridArea: "bankOptions",
    display: "grid",
    gridGap: 20,
    gridTemplateColumns: "1fr",
    color: theme.fontColor1,
  },

  item: {
    display: "grid",
    gridGap: 10,
    gridTemplateColumns: "1fr 110px",
    gridTemplateAreas: `
      "itemTitle        itemTitle"
      "itemDescription  itemTryNow"
    `,
  },

  itemTitle: {
    gridArea: "itemTitle",
  },

  itemDescription: {
    gridArea: "itemDescription",
    lineHeight: "24px"
  },

  itemTryNow: {
    gridArea: "itemTryNow",
    color: theme.fontColor3,
    cursor: "pointer",
    borderRadius: 10,
    padding: 10,
    border: `solid 2px ${theme.fontColor3}`,
    maxWidth: 110,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  itemComingSoon: {
    gridArea: "itemTryNow",
    color: theme.fontColor3,
    borderRadius: 10,
    padding: 10,
    border: `solid 2px ${theme.fontColor3}`,
    userSelect: "none",
    maxWidth: 110,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  payslips: {
    gridArea: "payslips",
    alignSelf: "stretch",
    justifySelf: "stretch",
    backgroundColor: theme.backgroundColor5,
    marginLeft: 10,
    padding: 30,

    display: "grid",
    gridRowGap: 20,
    gridTemplateRows: "auto 1fr",
    alignItems: "start",
    width: "100%",
    gridTemplateAreas: `
      "paySlipTitle"
      "paySlipOptions"
    `,
  },

  paySlipTitle: {
    gridArea: "paySlipTitle",
    color: theme.fontColor1,
    fontSize: 24,
    fontWeight: 300,
  },

  paySlipOptions: {
    gridArea: "paySlipOptions",
    display: "grid",
    justifyItems: "start",
    gridGap: 20,
    gridTemplateColumns: "1fr",
    color: theme.fontColor1,

  },

  supportedBanks: {
    gridArea: "supportedBanks",
  },

  document: {
    gridArea: "document",
    background: `linear-gradient(to bottom, ${theme.backgroundColor2} 0%, ${theme.backgroundColor2} 50%, ${theme.backgroundColor3} 50%, ${theme.backgroundColor3} 100%)`,
    display: "grid",
  },
  documentContainer: {
    backgroundColor: theme.backgroundColor4,
    justifySelf: "center",
    width: 600,
    padding: 30,
    display: "grid",
    gridRowGap: 20,
    gridTemplateAreas: `
      "documentTitle        itemTryNow"
      "documentDescription  itemTryNow"
    `,
  },
  documentTile: {
    gridArea: "documentTitle",
    color: theme.fontColor1,
    fontSize: 40,
    fontWeight: 300,
  },

  documentDescription: {
    gridArea: "documentDescription",
    color: theme.fontColor4,
  },

  kyc: {
    gridArea: "kyc",
    display: "grid",
    gridRowGap: 20,
    gridColumnGap: 10,
    backgroundColor: theme.backgroundColor3,
    color: theme.fontColor1,
    paddingTop: 50,
    paddingBottom: 50,
    gridTemplateAreas: `
      "kycTitle"
      "id"
    `,
  },

  kycTitle: {
    gridArea: "kycTitle",
    alignSelf: "center",
    justifySelf: "center",
    fontSize: 40,
    fontWeight: 300,
  },

  id: {
    marginLeft: "auto",
    marginRight: "auto",
    gridArea: "id",
    backgroundColor: theme.backgroundColor4,
    padding: 30,
    fontWeight: 300,
    maxWidth: 900,
    display: "grid",
    gridRowGap: 20,
    gridTemplateRows: "auto 1fr",
    gridTemplateAreas: `
      "idTitle"
      "idDetails"
      "itemTryNow"
    `,
  },

  idTitle: {
    gridArea: "idTitle",
    fontSize: 24,
  },

  idDetails: {
    gridArea: "idDetails",
    display: "grid",
    gridGap: 10,
    alignContent: "start",
    color: theme.fontColor4,
    fontWeight: 400,
    lineHeight: "24px",
  },

  footer: {
    gridArea: "footer",
    backgroundColor: theme.backgroundColor4,
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr",
    justifyItems: "center",
    color: theme.fontColor4,
    fontSize: 12,
    padding: 60,
  },
  link: {
    fontSize: 14,
    color: theme.linkColor,
    textDecoration: "none",
    fontWeight: 400,
  },
  note: {
    fontSize: 13,
    color: theme.fontColor2
  }
};

const config = {
  bankStatementFileUpload: {enabled: true, path: "/inv/bankstatement"},
  tryFetch: {enabled: false, path: "/inv/tryFetch"},
  bankStatementDEA: {enabled: false, path: ""},
  bankStatementOpenApi: {enabled: false, path: ""},
  payslipFileUpload: {enabled: false, path: "/inv/payslip/upload"},
  identityExtraction: {enabled: true, path: "/idv/identityExtraction/selectFile"},
  identityVerification: {enabled: true, path: "/idv/identityVerification/selectIdentity"},
};

const TryNow = ({navigateTo, path}) => {
  return (
    <div style={Style.itemTryNow} onClick={() => navigateTo(path)}>
      Try now
    </div>
  )
};

const ComingSoon = () => {
  return (
    <a style={{textDecoration: "none"}}
       target="_blank" rel="noopener noreferrer" href="https://sprinthive.com/get-access">
      <div style={Style.itemComingSoon}>
        Get Access
      </div>
    </a>
  )
};

const CallToAction = ({navigateTo, configItem}) => {
  return configItem.enabled ? <TryNow navigateTo={navigateTo} path={configItem.path}/> : <ComingSoon/>
};

export const Services = ({navigateTo}) => {
  return (
    <div style={Style.content}>
      <div style={Style.income}>
        <div style={Style.incomeTitle}>Income Verification</div>
        <div style={Style.bankStatements}>
          <div style={Style.bankTitle}>Bank statements</div>
          <div style={Style.bankOptions}>
            <div style={Style.item}>
              <div style={Style.itemTitle}>File upload</div>
              <div style={Style.itemDescription}>
                Upload a pdf bank statement and let our sophisticated data science solution determine the income.
              </div>
              <CallToAction navigateTo={navigateTo} configItem={config.bankStatementFileUpload}/>
            </div>
            <div style={Style.item}>
              <div style={Style.itemTitle}>Tryfetch</div>
              <div style={Style.itemDescription}>
                Connect to Tryfetch to extract bank statement data.
              </div>
              <CallToAction navigateTo={navigateTo} configItem={config.tryFetch}/>
            </div>
            <div style={Style.item}>
              <div style={Style.itemTitle}>DEA</div>
              <div style={Style.itemDescription}>
                Connect to Document Exchange Association to source bank statements directly from banks.
              </div>
              <CallToAction navigateTo={navigateTo} configItem={config.bankStatementDEA}/>
            </div>
            <div style={Style.item}>
              <div style={Style.itemTitle}>OpenAPI</div>
              <div style={Style.itemDescription}>
                Connect to any bank implementing the Open banking API (Nedbank) to extract bank statement data.
              </div>
              <CallToAction navigateTo={navigateTo} configItem={config.bankStatementOpenApi}/>
            </div>
          </div>
        </div>
        <div style={Style.payslips}>
          <div style={Style.paySlipTitle}>Payslips</div>
          <div style={Style.paySlipOptions}>
            <div style={Style.item}>
              <div style={Style.itemTitle}>File upload</div>
              <div style={Style.itemDescription}>
                Upload a pdf payslip and let our sophisticated data science solution determine the income.
              </div>
              <CallToAction navigateTo={navigateTo} configItem={config.payslipFileUpload}/>
            </div>
          </div>
        </div>
        <div style={Style.supportedBanks}>
          <FlexBox spaceEvenly  vCenter wrap flexGrow style={{minWidth: "100%"}}>
            <FlexBox>
              <img className="bankLogo" width={110} src={fnb} alt="FNB logo"/>
            </FlexBox>
            <FlexBox>
              <img className="bankLogo" width={150} height={150} src={standard} alt="Standard bank logo"/>
            </FlexBox>
            <FlexBox>
              <img className="bankLogo" width={150} height={150} src={nedbank} alt="Nedbank logo"/>
            </FlexBox>
            <FlexBox>
              <img className="bankLogo" width={150} height={150} src={capitec} alt="Capitec logo"/>
            </FlexBox>
            <FlexBox>
              <img className="bankLogo" height={60} src={absa} alt="ABSA logo"/>
            </FlexBox>
          </FlexBox>
        </div>
      </div>
      <div style={Style.document}>
        <div style={Style.documentContainer}>
          <div style={Style.documentTile}>Document classification</div>
          <div style={Style.documentDescription}>Post a file and we give you JSON</div>
          <ComingSoon/>
        </div>
      </div>
      <div style={Style.kyc}>
        <div style={Style.kycTitle}>Identity Verification</div>
        <div style={Style.id}>
          <div style={Style.idTitle}>BIOMETRIC FACE IDENTITY VERIFICATION</div>
          <div style={Style.idDetails}>
            <div>
              The Identity Verification demo allows a user to simply take a photo of their ID book or ID card and two selfies.
            </div>
            <div>
              Behind the scenes, using advanced data science, we extract the data and mugshot from the ID document and cross reference it with the selfie and the proof of presence to determine with a high level of certainty that the person is who they claim to be.
            </div>

            <div style={Style.note}>
              <p>
                The following demonstration has been restricted to South African ID books and ID cards.
              </p>
            </div>
          </div>
          <CallToAction navigateTo={navigateTo} configItem={config.identityVerification}/>
        </div>
      </div>
    </div>
  )
};

const ProductGallery = ({history}) => {
  const navigateTo = useCallback((path) => {
    history.push(path);
  }, [history]);

  return (
    <MainLayout>
      <Services navigateTo={navigateTo}/>
    </MainLayout>
  )
};

export default ProductGallery;
