import {useEffect} from "react";
import {fromEvent} from "rxjs";
import {filter, map} from "rxjs/operators";

/**
 * This hook registers a message listener and updates the height of the iframe to fit the contents
 *
 * References:
 * https://developer.yodlee.com/Yodlee_API/FastLink/FastLink_3.0_Detailed_Integration_Guide
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
 */
export const useFastLinkReSizer = () => {
  useEffect(() => {
    const subscription = fromEvent(window, "message").pipe(
      filter(e => e.data),
      map(e => ({
        height: e.data.height,
        element: document.querySelector("#container-fastlink iframe")
      })),
      filter(({height, element}) => height && element), // ensure that we have a height and a element
    ).subscribe(({height, element}) => {
      element.style.height = `${height}px`;
    });
    // clean subscription when the component un-mounts
    return () => subscription.unsubscribe();
  }, []); // only run on first render/on-mount
};