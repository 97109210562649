import {get} from "./get";
import uuid from "./uuid";

/**
 * A helper function which adds a random uuid to each transaction, data is expected to have a structure like:
 * {
 *   extractedData: {
 *     transactions: [
 *       {...}, {...}
 *     ]
 *   }
 * }
 *
 * This function changes data itself and does not return anything
 */

const generateTransactionIds = data => {
  // safely get transactions list
  const transactions = get(["extractedData", "transactions"], data);
  if (transactions) {
    data.extractedData.transactions = transactions.map(t => {
      t.transactionId = uuid();
      return t;
    })
  }
}

export default generateTransactionIds
