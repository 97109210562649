import React, {useEffect} from 'react'
import {identityEndpoint, useApiKey} from "AppConfig";
import {useUpload} from "hooks/useServerCall";
import {ErrorPage} from "components/error/ErrorPage";
import BannerLayout from "components/layout/BannerLayout";
import SelectProofOfIdentity from "../components/SelectProofOfIdentity";
import Banner from "./Banner";
import Loading from "../components/Loading";
import errorMapper from "./ErrorMapper";

const ErrorHandler = ({error, clearError}) => {
  const {response} = error;
  let message = undefined;
  if (response
    && response.data
    && response.data.identityDocument
    && response.data.identityDocument.errorCodes
    && response.data.identityDocument.errorCodes.length > 0) {
    message = errorMapper(response.data.identityDocument.errorCodes[0])
  }
  return <ErrorPage message={message} onRetry={clearError}/>
};

const SelectIdentity = ({history}) => {
  const apiKey = useApiKey();
  const url = `${identityEndpoint}/identityVerification/sync`;
  const [data, loading, error, start, clearError, percentCompleted ] = useUpload({url, apiKey});

  useEffect(() => {
    if (!loading && data) {
      history.push("/idv/identityVerification/identityResults", data);
    }
  }, [loading, data, history]);

  if (loading) {
    const loadingMessage = percentCompleted < 100 ? "Uploading..." : "Extracting identity data......";
    return <Loading banner={<Banner/>} loadingMessage={loadingMessage}/>;
  } else if (error) {
    return <ErrorHandler {...{error, clearError}}/>
  } else if (!loading && !data) {
    return (
      <BannerLayout banner={<Banner selectedIndex={0}/>}>
        <SelectProofOfIdentity {...{start}}/>
      </BannerLayout>
    )
  } else {
    return null
  }
};

export default SelectIdentity
