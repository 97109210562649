import React, {useState, useRef, useEffect} from "react";
import Radium from "radium";
import {Camera} from "react-feather";
import {theme} from "../../theme/theme";
import HSpinner from "../spinner/HSpinner";
import {mobileAndTabletCheck} from "../../lib/detectmobilebrowser";
import Button from "../button/Button";

const Style = {
  container: {
    display: "grid",
    gridRowGap: 20,
    gridTemplateAreas: `
    "videoOrLoading"
    "buttons"
    "canvas"
    `,
  },
  videoOrLoading: {
    gridArea: "videoOrLoading",
  },
  buttons: {
    gridArea: "buttons",
    display: "grid",
    justifyItems: "center",
    alignItems: "center",
  },
  canvas: {
    gridArea: "canvas",
    display: "none"
  },
  loading: {
    display: "grid",
    justifyItems: "center",
    alignItems: "center",
  },
  cameraButton: {
    cursor: "pointer",
    ":hover": {
      color: theme.linkColor,
    }
  },
  labelStyle: {cursor: "pointer", textAlign: "center"},
  hideFileInput: {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1
  },
};

export const ImageStatics = {
  desktop: {
    displaySize: {
      width: 960,
      height: 720,
    },
    captureSize: {
      width: 960,
      height: 720,
    }
  },
  mobile: {
    displaySize: {
      width: 220,
      height: 140,
    }
  },
}

const VideoOrLoading = Radium(({videoRef, loadingCamera}) => {
  return (
    <div style={Style.videoOrLoading}>
      <div style={[Style.loading, {display: loadingCamera ? "grid" : "none", minWidth: 320}]}>
        <HSpinner/>
      </div>
      <video ref={videoRef}
             autoPlay={true}
             style={[Style.videoOrLoading, {display: !loadingCamera ? null : "none"}]}
             width={ImageStatics.desktop.captureSize.width}
             height={ImageStatics.desktop.captureSize.height}/>
    </div>
  )
});

const Buttons = Radium(({loadingCamera, takePic}) => {
  return (
    <div style={[Style.buttons, {display: !loadingCamera ? "grid" : "none"}]}>
      <span style={Style.cameraButton}>
        <Camera size={34} onClick={takePic}/>
      </span>
    </div>
  )
});

const useMedia = (player, setLoadingCamera) => {
  // When the component mounts start the video stream
  useEffect(() => {
    const _player = player;
    const constraints = {
      video: {
        width: 960,
        height: 720,
        facingMode: "user"
      }
    };
    setLoadingCamera(true);
    // todo convert this to an rx observable so that it can be cancelled when we unmount
    navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        _player.current.srcObject = stream;
        setTimeout(() => {
          setLoadingCamera(false);
        }, 1500)
      })
      .catch(e => console.log("An error occurred loading the camera", e));

    // when the component un-mounts stop the video stream
    return () => {
      if (_player.current && _player.current.srcObject) {
        _player.current.srcObject.getVideoTracks().forEach(track => track.stop());
      }
    }
  }, [player, setLoadingCamera]);
};

const CaptureImage = ({onImageCaptured}) => {
  const [loadingCamera, setLoadingCamera] = useState(true);
  const video = useRef(undefined);
  const canvas = useRef(undefined);

  useMedia(video, setLoadingCamera);

  const takePic = () => {
    const context = canvas.current.getContext('2d');
    const {width, height} = canvas.current;
    context.drawImage(video.current, 0, 0, width, height);
    onImageCaptured({
      base64Str: canvas.current.toDataURL(),
      width: ImageStatics.desktop.captureSize.width,
      height: ImageStatics.desktop.captureSize.height
    });
  };

  return (
    <div style={Style.container}>
      <VideoOrLoading videoRef={video} loadingCamera={loadingCamera}/>
      <Buttons loadingCamera={loadingCamera} takePic={takePic}/>
      <canvas ref={canvas}
              style={Style.canvas}
              width={ImageStatics.desktop.captureSize.width}
              height={ImageStatics.desktop.captureSize.height}/>
    </div>
  )
};

const calculateImageDimensions = (base64Str, onChange) => {
  const image = new Image();
  image.onload = () => onChange({base64Str, width: image.width, height: image.height});
  image.src = base64Str;
};

const CaptureImageFromMobileOrTablet = ({instanceId = "captureImageFromMobileOrTablet", disabled, onChange}) => {
  return (
    <div>
      <input style={Style.hideFileInput} id={instanceId} type="file" capture="camera"
             onChange={e => {
               if (e.target.files && e.target.files[0]) {
                 const file = e.target.files[0];
                 const reader = new FileReader();
                 reader.onload = () => calculateImageDimensions(reader.result, onChange);
                 reader.readAsDataURL(file);
               } else {
                 console.warn("No file found...")
               }
             }} accept="image/jpeg"/>
      <label style={Style.labelStyle} htmlFor={instanceId}>
        <Button disabled={disabled}
                text="Open camera"/>
      </label>
    </div>
  );
};

const CaptureImageSwitch = ({onImageCaptured}) => mobileAndTabletCheck()
  ? <CaptureImageFromMobileOrTablet onChange={onImageCaptured}/>
  : <CaptureImage onImageCaptured={onImageCaptured}/>

export default CaptureImageSwitch


