import React from 'react'
import {Redirect, Route, Switch, withRouter} from "react-router";
import IdvDemoV3 from "mobile/pages/identity/IdvDemoV3";
import Intro from "mobile/pages/identity/intro/Intro";
import IdentityDocument from "mobile/pages/identity/idDoc/IdentityDocument";
import Selfie from "mobile/pages/identity/selfie/Selfie";
import Pop from "mobile/pages/identity/pop/Pop";
import Results from "mobile/pages/identity/results/Results";

export default withRouter(() => {
  return (
    <React.Fragment>
      <Switch>
        <Route path="/storyboard" exact component={IdvDemoV3} />
        <Route path="/" exact component={Intro} />
        <Route path="/idv/intro" exact component={Intro} />
        <Route path="/idv/idDocument" exact component={IdentityDocument} />
        <Route path="/idv/selfie" exact component={Selfie} />
        <Route path="/idv/pop" exact component={Pop} />
        <Route path="/idv/results" exact component={Results} />
        <Route>
          <Redirect to={"/"}/>
        </Route>
      </Switch>
    </React.Fragment>
  )
})
