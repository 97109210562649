import React from 'react'
import Radium from "radium";
import {theme} from "../../theme/theme";

const Style = {
  list: {
    display: "grid",
    gridRowGap: 5,
    gridTemplateRows: "repeat(4,auto)",

  },
  listItem: {
    flex: 1,
    color: theme.fontColor4,
  },
};
export const List = Radium(({style, children}) => <div style={[Style.list, style]}>{children}</div>);
export const ListItem = ({children}) => <div style={Style.listItem}>{children}</div>;