import React from "react";
import noneIfNull from "lib/noneIfNull";
import TitleValueCard from "components/cards/TitleValueCard";
import {useBankStatementContext} from "../bankstatement/BankStatementContext";

const Style = {
  container: {
    display: "grid",
    gridGap: 10,
    justifyItems: "start",
    gridTemplateColumns: "repeat(1, auto)",
    gridTemplateRows: "repeat(3, auto)",
  }
}

export const IncomeResult = ({estimatedMonthlyIncome}) => {
  return (
    <div style={Style.container}>
      <TitleValueCard large title="Estimated income" value={noneIfNull(estimatedMonthlyIncome)}/>
    </div>
  )
}

const IncomeResultWrapper = () => {
  const {startBatch} = useBankStatementContext();
  const {estimatedMonthlyIncome} = startBatch.data;
  return (
    <IncomeResult {...{estimatedMonthlyIncome}}/>
  )
}

export default IncomeResultWrapper
