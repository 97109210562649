import React, {useEffect, useRef, useState} from "react";
import {TimeChart} from "../../../../components/charts/timeChart/TimeChart";
import {useMeasure} from "../../../../hooks/useMeasure";
import moment from "moment";
import Placeholder from "../../../../components/Placeholder";
import {theme} from "../../../../theme/theme";
import {useBankStatementContext} from "../../bankstatement/BankStatementContext";

const Style = {
  container: {},
  selectedItem: {
    paddingTop: 5,
    paddingLeft: 10,
    borderTop: `solid 1px ${theme.borderColor1}`,
    display: "grid",
    gridRowGap: 5,
    gridColumnGap: 10,
    gridTemplateRows: "15px",
    gridTemplateColumns: "auto auto auto 1fr",
    minHeight: 20,
    fontSize: 12,
    color: theme.fontColor4,
  },
  debit: {
    color: "#8d2b30",
    fontWeight: 600
  },
  credit: {
    color: "#5f9f63",
    fontWeight: 600
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 600,
    textTransform: "uppercase",
    color: "#b7b7b7",
    justifySelf: "end"
  },
  selectedItemInfo: {
    marginTop: 4
  }
};

export const ShowSelectedItem = ({itemSelected}) => {
  return itemSelected ?
    <div style={Style.selectedItem}>
      <div style={Style.selectedItemInfo}>Date of
        transaction {moment(itemSelected.date, "YYYY-MM-DD").format("DD ddd MMM YYYY")}</div>
      <div style={Style.selectedItemInfo}>Debit <span style={Style.debit}>{itemSelected.debit}</span></div>
      <div style={Style.selectedItemInfo}>Credit <span style={Style.credit}>{itemSelected.credit}</span></div>
      <div style={Style.sectionTitle}>Transactions</div>
    </div> :
    <div style={Style.selectedItem}>
      <div/>
      <div/>
      <div/>
      <div style={Style.sectionTitle}>Transactions</div>
    </div>
};

const PerDayChart = ({data}) => {
  const [itemSelected, setItemSelected] = useState(undefined);
  const chartRef = useRef(undefined);
  const {width} = useMeasure(chartRef);
  const margin = {top: 10, right: 0, bottom: 55, left: 50};
  const groupBy = "day";

  return (
    <div className="PerDayChart" ref={chartRef} style={Style.container}>
      <ShowSelectedItem itemSelected={itemSelected}/>
      {data && data.length > 0 && width > 0
        ? <TimeChart {...{width, height: 200, margin, data, groupBy, setItemSelected}}/>
        : null
      }
    </div>
  )
};

const PerDayChartContainer = props => {
  const {startBatch} = useBankStatementContext();
  const {transactions} = props.extractedData || startBatch.data.extractedData;

  const [data, setData] = useState(undefined);

  useEffect(() => {
    const _data = transactions.reduce((acc, t) => {
      const found = acc[t.date];
      if (!found) {
        acc[t.date] = {date: t.date, key: moment(t.date, "YYYY-MM-DD").toDate(), credit: 0, debit: 0}
      }
      if (t.transactionValue > 0) {
        acc[t.date].credit += t.transactionValue;
      } else {
        acc[t.date].debit += (t.transactionValue * -1);
      }
      return acc;
    }, {});

    setData(Object.keys(_data).map(k => {
      _data[k].debit = _data[k].debit.toFixed(2);
      _data[k].credit = _data[k].credit.toFixed(2);
      return _data[k];
    }));
  }, [transactions]);

  return data
    ? <PerDayChart data={data}/>
    : <Placeholder/>;
}

export default PerDayChartContainer
