import React from "react";
import TransactionListFilter from "../../../../components/transactionList/TransactionListFilter";
import {useBankStatementContext} from "../../bankstatement/BankStatementContext";

const TransactionFilterContainer = () => {
  const {onTransactionFilterChanged: onFilterChanged, transactionFilter} = useBankStatementContext();
  return (
    <TransactionListFilter {...{onFilterChanged, transactionFilter}}/>
  )
}

export default TransactionFilterContainer
