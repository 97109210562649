import React from "react";
import FlexBox from "../layout/FlexBox";

const infoCardStyle = {
  container: {
    display: "grid",
    gridTemplateRows: "auto 1fr",
    gridGap: 5,
  },
  header: {
    fontSize: 14,
    fontWeight: 800,
    textTransform: "uppercase",
    color: "#818181"
  }
};

const InfoCard = (props) => {
  return (
    <FlexBox item>
      <div style={infoCardStyle.container}>
        <div style={infoCardStyle.header}>
          {props.title || "Title here"}
        </div>
        <div style={infoCardStyle.body}>
          {props.children}
        </div>
      </div>
    </FlexBox>
  )
};

export default InfoCard;