import React from "react";
import Radium from "radium";
import {theme} from "../../theme/theme";
import {Send, X} from "react-feather";
import {ImageStatics} from "components/captureImage/CaptureImage"
import {calculateAspectRatioFit} from "../../lib/imageUtils";
import {mobileAndTabletCheck} from "../../lib/detectmobilebrowser";

const Style = {
  container: {
    display: "grid",
    gridRowGap: 20,
    gridTemplateRows: "auto auto 1fr",
  },
  buttons: {
    display: "grid",
    marginLeft: 10,
    marginRight: 10,
    gridTemplateColumns: "auto auto",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    display: "grid",
    justifyItems: "center",
    maxWidth: 40,
    cursor: "pointer",
    ":hover": {
      color: theme.linkColor,
    }
  }
};

const Buttons = Radium(({onImageAccepted, onRetakeImage}) => {
  return (
    <div style={Style.buttons}>
      <div key="retake" style={Style.button}>
        <X size={34} onClick={onRetakeImage}/>
      </div>
      <div key="accept" style={Style.button}>
        <Send size={34} onClick={onImageAccepted}/>
      </div>
    </div>
  )
});

const ConfirmImage = ({onImageAccepted, onRetakeImage, imageData, alt="A picture of you"}) => {
  const size = mobileAndTabletCheck()
    ? {
      width: ImageStatics.mobile.displaySize.width,
      height: ImageStatics.mobile.displaySize.height
  } : {
    width: ImageStatics.desktop.displaySize.width,
    height: ImageStatics.desktop.displaySize.height
  }
  const {width, height} = calculateAspectRatioFit(imageData.width, imageData.height, size.width, size.height);
  return (
    <div style={Style.container}>
      <img src={imageData.base64Str} width={width} height={height} alt={alt}/>
      <Buttons {...{onImageAccepted, onRetakeImage}}/>
    </div>
  )
};

export default ConfirmImage

