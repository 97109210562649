import CheckBox from "components/checkBox/CheckBox"
import {theme} from "theme/theme"

const TermsLink = () => <a style={{color: theme.fontColor4}} href="https://sprinthive.com/legal/DemoSiteTermsAndConditions-2020-09.pdf" target="_blank" rel="noopener noreferrer">terms and conditions</a>
const PrivacyLink = () => <a style={{color: theme.fontColor4}} href="https://sprinthive.com/privacy-notice" target="_blank" rel="noopener noreferrer">privacy policy</a>

const AcceptTermsAndConditions = ({setConsent}) =>
<CheckBox label={<span>I agree to the <TermsLink/> and <PrivacyLink/></span>}
onChanged={value => setConsent(value)}/>

export default AcceptTermsAndConditions
