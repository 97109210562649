import * as d3 from "d3";

export function getInterval(groupBy) {
  switch (groupBy) {
    case 'hour':
      return d3.timeHour

    case 'day':
      return d3.timeDay

    case 'month':
      return d3.timeMonth

    default:
      throw Error('Unknown groupBy expected hour day week month year')
  }
}

export function getXFormatStr(groupBy) {
  switch (groupBy) {
    case 'month':
      return '%M'

    case 'hour':
      return '%H'

    case 'day':
      return '%d'

    default:
      throw Error('Unknown groupBy expected hour day week month year')
  }
}

export function getXXFormatStr(groupBy) {
  switch (groupBy) {
    case 'month':
      return '%B %Y'

    case 'hour':
      return "%d %A %B %Y"

    case 'day':
      return "%A %B %Y"

    default:
      throw Error(`Unknown groupBy expected hour || day || week || month || year, received ${groupBy}`)
  }
}

export function getXTicks(groupBy, width) {
  switch (groupBy) {
    case 'month':
      return 12

    case 'hour':
      return width < 1000 ? 24 : 48

    case 'day':
      return width < 1000 ? 20 : 31

    default:
      throw Error('Unknown groupBy expected hour day week month year')
  }
}

export function getXXTicks(groupBy, width) {
  switch (groupBy) {
    case 'month':
      return 6

    case 'hour':
      return width < 1000 ? 2 : 2

    case 'day':
      return width < 1000 ? 3 : 3

    default:
      throw Error('Unknown groupBy expected hour day week month year')
  }
}

export const mapToDayOfWeek = (dayOfWeekAsInt) => {
  switch (dayOfWeekAsInt) {
    case (1):
      return "Mon";
    case (2):
      return "Tues";
    case (3):
      return "Wed";
    case (4):
      return "Thu";
    case (5):
      return "Fri";
    case (6):
      return "Sat";
    case (7):
      return "Sun";
    default:
      console.warn(`Unknown dayOfWeekAsInt found, expected 0-6 actual: ${dayOfWeekAsInt}` )
  }
};

