import React from "react"
import FlexBox from "components/layout/FlexBox"
import {ErrorPage} from "components/error/ErrorPage"
import mapErrorCodeToMessage from "pages/identity/verification/ErrorMapper"

const ErrorHandler = ({errorCodes, resetErrors}) => {
  // when we have errorCodes use it to map them to a message worthy of displaying to a user
  const message = errorCodes.length > 0 ? mapErrorCodeToMessage(errorCodes[0]) : undefined
  return (
    <FlexBox centered flexGrow>
      {
        message
          ? <ErrorPage
            noLayout
            message={message}
            onRetry={resetErrors}/>
          : <ErrorPage noLayout/>
      }
    </FlexBox>
  )
}

export default ErrorHandler
