import React, {useRef, useEffect} from "react";
import * as d3 from "d3";

const useDrawBars = (containerRef, data, xScale, yScale, heightLessMargin, onClicked) => {
  useEffect(() => {
    const selection = d3.select(containerRef.current);
    const x = selection
      .selectAll('rect')
      .data(data, d => {
        return d.key
      });

    const enter = x.enter();
    enter
      .append('rect')
      // .on("click", function(d) {
      //   if (onClicked) {
      //     const currentColour = d3.select(this).style("fill");
      //     d3.select(this).style("fill", currentColour === "rgb(170, 170, 170)" ? "rgb(218,218,218)" : "rgb(170, 170, 170)");
      //     onClicked && onClicked(d);
      //   }
      // })
      .style('fill', "#8d2b30")
      .attr('x', d => xScale(d.key))
      .attr('y', heightLessMargin)
      .attr('height', 0)
      .attr('width', xScale.bandwidth() / 2)
      .transition()
      .duration(100)
      .delay((d, i) => {
        return i * 15
      })
      .attr('y', function (d) {
        return yScale(d.debit);
      })
      .attr('height', function (d) {
        return heightLessMargin - yScale(d.debit);
      });

    enter
      .append('rect')
      // .on("click", function(d) {
      //   if (onClicked) {
      //     const currentColour = d3.select(this).style("fill");
      //     d3.select(this).style("fill", currentColour === "rgb(170, 170, 170)" ? "rgb(218,218,218)" : "rgb(170, 170, 170)");
      //     onClicked && onClicked(d);
      //   }
      // })
      .style('fill', "#5f9f63")
      .attr('x', d => xScale(d.key) + xScale.bandwidth() / 2)
      .attr('y', heightLessMargin)
      .attr('height', 0)
      .attr('width', xScale.bandwidth() / 2)
      .transition()
      .duration(100)
      .delay((d, i) => {
        return i * 15
      })
      .attr('y', function (d) {
        return yScale(d.credit);
      })
      .attr('height', function (d) {
        return heightLessMargin - yScale(d.credit);
      });




    x.transition()
      .duration(100)
      .delay((d, i) => {
        return i * 15
      })
      .attr('y', function (d) {
        const y = yScale(d.value);
        return y >= 0 ? y : 0;
      })
      .attr('height', function (d) {
        const hy = heightLessMargin - yScale(d.value);
        return hy >= 0 ? hy : 0;
      })
  });
};

const Bars = props => {
  const barsRef = useRef(null);
  const {widthLessMargin, heightLessMargin, data, xScale, yScale, onItemSelected} = props;

  useDrawBars(barsRef, data, xScale, yScale, heightLessMargin, onItemSelected);

  return (
    <g ref={barsRef}
       width={widthLessMargin}
       height={heightLessMargin}/>
  )
}

export default Bars
