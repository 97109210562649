import React, {useState, useCallback} from "react"
import Radium from "radium"
import {theme} from "theme/theme"
import CheckBox from "components/checkBox/CheckBox"

const Style = {
  container: {
    display: "grid",
    gridGap: 10,
    justifyItems: "stretch",
    gridTemplateColumns: "repeat(1, auto)",
    gridTemplateRows: "repeat(3, auto)",
    color: theme.fontColor4,
  },
  card: {
    display: "grid",
    gridColumnGap: 25,
    gridRowGap: 10,
    gridTemplateColumns: "50px 95px 150px 150px 1fr",
    gridTemplateRows: "repeat(1, auto)",
    fontSize: 14
  },
  header: {
    borderBottom: `solid 1px ${theme.borderColor1}`,
    color: theme.fontColor4,
    fontSize: 18
  },
  total: {
    display: "grid",
    gridColumnStart:4,
    height: 40,
    justifyContent: "end",
    alignItems: "center",
    borderBottom: "solid 2px",
    borderTop: "solid 1px",
    fontSize: 18,
    fontWeight: 600,
  }
};

const Card = ({transaction, onTransactionSelected}) => {
  const {transactionId, date, description, balance, transactionValue} = transaction;
  const onChanged = useCallback((selected) => {
    onTransactionSelected({selected, transactionId, transactionValue});
  }, [onTransactionSelected, transactionId, transactionValue]);

  return (
    <div style={Style.card}>
      <div><CheckBox onChanged={onChanged}/></div>
      <div>{date}</div>
      <div style={{justifySelf: "end"}}>{balance.toFixed(2)}</div>
      <div style={{justifySelf: "end"}}>{transactionValue.toFixed(2)}</div>
      <div>{description}</div>
    </div>
  )
};

const HeaderRow = Radium(() => {
  return (
    <div style={[Style.card, Style.header]}>
      <div/>
      <div>Date</div>
      <div style={{justifySelf: "end"}}>Balance</div>
      <div style={{justifySelf: "end"}}>Amount</div>
      <div>Description</div>
    </div>
  )
});

const TotalRow = Radium(({total}) => {
  return (
    <div style={[Style.card]}>
      <div style={Style.total}>{total.toFixed(2)}</div>
    </div>
  )
});

const TransactionList = ({transactions}) => {
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const onTransactionSelected = useCallback((transaction) => {
    if (transaction.selected) {
      setSelectedTransactions(st => st.concat([transaction.transactionId]));
    } else {
      setSelectedTransactions(st => st.filter(transactionId => transactionId !== transaction.transactionId));
    }
  }, [setSelectedTransactions]);

  const totalSelectedAmount = transactions
    .filter(t => selectedTransactions.indexOf(t.transactionId) !== -1)
    .reduce((acc, t) => {
      acc += t.transactionValue;
      return acc;
    }, 0);

  return (
    <div style={Style.container}>
      <HeaderRow/>
      {
        transactions.map((transaction, i) => <Card key={`${i}-transaction-item`}
                                                  onTransactionSelected={onTransactionSelected}
                                                  transaction={transaction}/>)
      }
      <TotalRow total={totalSelectedAmount}/>
    </div>
  )
};

export default TransactionList
