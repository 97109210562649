import React, {useRef, useEffect} from "react";
import * as d3 from "d3";
import "./XAxis.css";
import moment from "moment";

const useDrawXAxis = (layer, xScale) => {
  useEffect(() => {
    const hourOfDay = d3.axisBottom(xScale)
      .tickArguments([d3.timeDay.every(5)])
      .tickPadding(5)
      .tickFormat(d3.timeFormat("%d"));

    d3.select(layer.current)
       .call(hourOfDay)
      .selectAll("text")
      .style("text-anchor", 'start')
      .style("fill", '#c1c1c1')
      .style("stroke", 'none')

  });
};

const useDrawXXAxis = (layer, xScale) => {
  useEffect(() => {
    const dayMonthYear = d3.axisBottom(xScale)
      .tickArguments([d3.timeDay.every(5)])
      .tickPadding(5)
      .tickSize(0)
      .tickFormat((d, i, j) => {
        if (moment(d).date() === 30 || moment(d).date() === 31) return "";
        return i < j.length -1 ? d3.timeFormat("%a")(d) : ""
      });

    d3.select(layer.current)
       .call(dayMonthYear)
      .selectAll("text")
      .style("text-anchor", 'start')
      .style("fill", '#c1c1c1')
      .style("stroke", 'none')

  });
};

const useDrawXXXAxis = (layer, xScale) => {
  useEffect(() => {
    const dayMonthYear = d3.axisBottom(xScale)
      .tickArguments([d3.timeMonth.every(1)])
      .tickPadding(5)
      .tickSize(0)
      .tickFormat((d) => {
        return d3.timeFormat("%B %Y")(d);
      });

    d3.select(layer.current)
       .call(dayMonthYear)
      .selectAll("text")
      .style("text-anchor", 'start')
      .style("fill", '#c1c1c1')
      .style("stroke", 'none')

  });
};

const XAxis = props => {
  const layer1 = useRef(null);
  const layer2 = useRef(null);
  const layer3 = useRef(null);
  const {heightLessMargin, xScale, groupBy} = props;
  useDrawXAxis(layer1, xScale, groupBy);
  useDrawXXAxis(layer2, xScale);
  useDrawXXXAxis(layer3, xScale);

  return (
    <React.Fragment>
      <g ref={layer1} className="x-axis" transform={`translate(0, ${heightLessMargin})`}/>
      <g ref={layer2} className="xx-axis" transform={`translate(0, ${heightLessMargin + 20})`}/>
      <g ref={layer3} className="xx-axis" transform={`translate(0, ${heightLessMargin + 35})`}/>
    </React.Fragment>
  )
}

export default XAxis
