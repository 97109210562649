import React from "react";
import Banner from "./Banner";
import BannerLayout from "components/layout/BannerLayout";
import {theme} from "theme/theme";
import Button from "components/button/Button";
import ImagesView from "../components/ImagesView";
import TitleValueGridLayout from "components/cards/TitleValueGridLayout";
import SelfieView from "../components/SelfieView";
import PopView from "../components/PopView";

const Style = {
  container: {
    display: "grid",
    gridGap: 20,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 30,
    gridTemplateColumns: "350px 1fr 250px",
    gridTemplateRows: "auto auto auto 1fr",
    gridTemplateAreas: `
      "images  content cta"
      "images  selfie  cta"
      "images  pop     cta"
      "images  spacer  cta"
    `,
  },
  images: {
    gridArea: "images",
    display: "grid",
    gridGap: 20,
    paddingTop: 10,
    paddingBottom: 10,
    justifyItems: "center",
    alignItems: "start",
    backgroundColor: theme.backgroundColor3,
  },
  cta: {
    gridArea: "cta",
    display: "grid",
    alignItems: "start",
    gridRowGap: 10,
    gridTemplateRows: "auto auto auto 1fr",
  },
};

const CallToAction = ({onNextClicked, onCancelClicked, onRestart}) => {
  return (
    <div style={Style.cta}>
      <div>
        Nice, you have successfully completed the identity extraction would you like to continue?
      </div>
      <Button onClick={onNextClicked}>Yes, continue</Button>
      <Button onClick={onRestart}>Start again</Button>
      <Button onClick={onCancelClicked}>Get me out of here!</Button>
    </div>
  )
};

const IdentityVerificationResults = ({history, location: {state}}) => {
  const onNextClicked = () => {
    history.push("/idv/identityVerification/selfie", state)
  };

  const onCancelClicked = () => {
    history.push("/", state)
  };

  const onRestart = () => {
    history.push("/idv/identityVerification/selectIdentity")
  };

  const {providedImageUrl, documentValidationsPassed} = state.data.identityDocument;
  const {identityNumber, firstName, middleNames, lastName, gender, initials, nationality, countryOfBirth, dateOfBirth,
    identityPhotoUrl} = state.data.identityDocument.extractedData;

  const fields = [
    {title: "Identity number", value: identityNumber},
    {title: "Initials", value: initials},
    {title: "First name", value: firstName},
    {title: "Middle names", value: middleNames},
    {title: "Last name", value: lastName},
    {title: "Gender", value: gender},
    {title: "Date of birth", value: dateOfBirth},
    {title: "Country of birth", value: countryOfBirth},
    {title: "Nationality", value: nationality},
    {title: "Document data", value: Boolean(documentValidationsPassed) ? "Consistent" : "Inconsistent"}
  ];

  const titleUrls = [
    {title: "Original image", imageUrl: providedImageUrl},
    {title: "Extracted image", imageUrl: identityPhotoUrl},
  ];

  return (
    <BannerLayout banner={<Banner selectedIndex={0}/>}>
      <div style={Style.container}>
        <TitleValueGridLayout {...{data: fields}}/>
        <ImagesView {...{titleUrls}}/>
        <SelfieView {...state.data.selfie}/>
        <PopView {...state.data.proofOfPresence}/>
        <CallToAction {...{onNextClicked, onCancelClicked, onRestart}}/>
      </div>
    </BannerLayout>
  )
};

export default IdentityVerificationResults
