import {useState, useEffect} from "react";
import moment from "moment";

const useTransactionSummaryReducer = (transactions) => {
  const [data, setData] = useState(undefined);

  useEffect(() => {
    const statementStartDate = transactions[0].date;
    const statementEndDate = transactions[transactions.length-1].date;

    const ans = {
      statementStartDate,
      statementEndDate,
      numberOfDebits: 0,
      numberOfCredits: 0,
      debitAmount: 0,
      creditAmount: 0,
      duration: 0,
      numberOfTransactions: transactions.length
    };

    transactions.forEach(t => {
      if (t.transactionValue > 0) {
        ans.numberOfCredits++;
        ans.creditAmount += t.transactionValue;
      } else {
        ans.numberOfDebits++;
        ans.debitAmount += t.transactionValue;
      }
    });

    const startDate = moment(statementStartDate, "YYYY-MM-DD").toDate().getTime();
    const endDate = moment(statementEndDate, "YYYY-MM-DD").toDate().getTime();
    ans.duration = (endDate - startDate) / (1000 * 60 * 60 * 24);
    ans.debitAmount = ans.debitAmount.toFixed(2);
    ans.creditAmount = ans.creditAmount.toFixed(2);
    setData(ans);

  }, [transactions]);

  return data;
}

export default useTransactionSummaryReducer
