import React, {useCallback} from "react"
import PropTypes from "prop-types"
import Radium from "radium"
import {theme} from "theme/theme"

const Style = {
  container: {
    display: "grid",
    gridGap: 10,
    gridTemplateColumns: "1fr repeat(3, auto)",
    fontSize: 12,
  },
  item: {
    borderRadius: 15,
    padding: 5,
    minWidth: 50,
    textAlign: "center",
    cursor: "pointer",
    backgroundColor: theme.backgroundColor4,
    color: theme.fontColor4,
    userSelect: "none",
  },
  selected: {
    backgroundColor: theme.backgroundColor5,
    color: theme.linkColor,
  }
}

const FilterItem = Radium(({onClick, selected, children}) => {
  return <div style={[
    Style.item,
    selected && Style.selected
  ]} onClick={onClick}>{children}</div>
})

const TransactionListFilter = ({onFilterChanged, transactionFilter}) => {
  const credits = useCallback(() => onFilterChanged({transactionFilter: "credits"}), [onFilterChanged])
  const debits = useCallback(() => onFilterChanged({transactionFilter: "debits"}), [onFilterChanged])
  const all = useCallback(() => onFilterChanged({transactionFilter: "all"}), [onFilterChanged])

  return (
    <div style={Style.container}>
      <div></div>
      <FilterItem onClick={credits} selected={transactionFilter === "credits"}>Credits</FilterItem>
      <FilterItem onClick={debits} selected={transactionFilter === "debits"}>Debits</FilterItem>
      <FilterItem onClick={all} selected={transactionFilter === "all"}>All</FilterItem>
    </div>
  )
}

TransactionListFilter.propTypes = {
  onFilterChanged: PropTypes.func.isRequired,
  transactionFilter: PropTypes.string.isRequired,
}

export default TransactionListFilter
