import React, {createContext, useContext} from "react";
import {callApi} from "lib/callApi";
import {combineLatest, from, merge, of} from "rxjs";
import {map, mergeMap, scan, startWith} from "rxjs/operators";
import {withEffects} from "refract-rxjs";
import axios from "axios";
import {incomeVerificationEndpoint} from "AppConfig";
import generateTransactionIds from "lib/generateTransactionIds";
import {uploadProgressHandler} from "lib/axiosUtils";

const Context = createContext({});
export const usePayslipContext = () => useContext(Context);

const uploadFiles = (files) => {
  const formData = new FormData();
  files.forEach(f => formData.append("file", f, f.name));

  const [onUploadProgress, uploadProgress$] = uploadProgressHandler();
  const request$ = from(axios.post(`${incomeVerificationEndpoint}/verifyIncome/doc/templateRead/file`,
    formData, {onUploadProgress}))
    .pipe(
      mergeMap(({data, headers}) => {
        const idToken = headers['x-application-token'];
        generateTransactionIds(data);
        // change the page urls to include incomeVerificationEndpoint
        data.pages = data.pages.map(p => `${incomeVerificationEndpoint}${p}`);
        data.pageImageUrl = data.pages[0];
        return of({data, authToken: {tokenType: "Bearer", idToken}});
      })
    );

  return merge(uploadProgress$, request$);
};

const aperture = component => {
  const children$ = component.observe("children");
  const [fieldUpdated$, onFieldUpdated] = component.useEvent("fieldUpdated");
  const [batchState$] = callApi({createEvent: component.useEvent, namespace: "startBatch", apiFn: uploadFiles});

  const context$ = merge(batchState$, fieldUpdated$).pipe(
    startWith({onFieldUpdated}),
    scan((state, value) => ({...state, ...value})),
  );

  return combineLatest([children$, context$]).pipe(
    map(([children, context]) => {
      return (
        <Context.Provider value={context}>
          {children}
        </Context.Provider>
      )
    }),
  )
};

const PayslipContextWithEffects = withEffects(aperture)();
export default PayslipContextWithEffects
