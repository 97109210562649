import React from "react";
import TitleValueCard from "../../../components/cards/TitleValueCard";
import {useBankStatementContext} from "../bankstatement/BankStatementContext";

const Style = {
  container: {
    display: "grid",
    gridGap: 10,
    justifyItems: "start",
    gridTemplateColumns: "auto 1fr",
    gridTemplateRows: "repeat(3, auto)",
  }
};

const BankDetails = ({accountNumber, bank}) => {
  return (
    <div style={Style.container}>
      <TitleValueCard title="Bank" value={bank}/>
      <TitleValueCard title="Account number" value={accountNumber}/>
    </div>
  );
};

const BankDetailsContainer = () => {
  const {startBatch} = useBankStatementContext();
  const {accountType, accountNumber, bank} = startBatch.data.extractedData.bankAccount;

  return (
    <BankDetails {...{accountType, accountNumber, bank}}/>
  )
}

export default BankDetailsContainer
