import axios from "axios";
import {incomeVerificationEndpoint} from "../../../AppConfig";
import {from} from "rxjs";

export const getUserToken = () => {
  return axios.post(`${incomeVerificationEndpoint}/verifyIncome/yodlee/userToken`)
};

export const estimateIncome = ({userToken}) => {
  const data = {userToken};
  return from(axios.post(`${incomeVerificationEndpoint}/verifyIncome/yodlee/estimateIncome`, data)
    .then(res => {
      return res.data
    }));
};
