import React, {useState} from 'react'
import {theme, themeStyle} from "theme/theme"
import idBook from "mobile/pages/identity/idDoc/idbook.png"
import idCard from "mobile/pages/identity/idDoc/idcard.png"
import FileSelector from "components/fileSelector/FileSelector"
import Button from "components/button/Button"
import {X} from "react-feather"
import Radium from "radium"
import ImageFromFile from "components/imageFromFile/ImageFromFile"
import FlexBox from "components/layout/FlexBox"
import AcceptTermsAndConditions from "components/AcceptTermsAndConditions"

const Style = {
  container: {
    display: "grid",
    gridGap: 10,

    marginLeft: 40,
    marginRight: 40,
    marginBottom: 20,
    justifyItems: "center",
    alignItems: "start",
    gridTemplateRows: "repeat(5,auto) 1fr",
  },
  list: {
    display: "grid",
    gridRowGap: 5,
    gridTemplateRows: "repeat(2,auto)",

  },
  listItem: {
    flex: 1,
    color: theme.fontColor4,
  },
  fileList: {
    marginTop: 30,
    display: "grid",
    gridGap: 10,
    color: theme.fontColor4,
  },
  fileItem: {
    display: "grid",
    gridGap: 10,
    gridTemplateColumns: "auto auto",
    gridTemplateRows: "auto",
    alignItems: "center",
    ":hover": {
      backgroundColor: "rgba(0,0,0,0.1)"
    },
  },
  fileItemRemove: {
    alignSelf: "start",
    cursor: "pointer"
  },
  buttonBar: {
    display: "grid",
    gridGap: 10,
    gridTemplateColumns: "auto auto",
    gridTemplateRows: "auto"
  },
  placeholder1: {
    backgroundColor: theme.backgroundColor1,
    minWidth: 300,
    height: "100%"
  },
  placeholder2: {
    backgroundColor: theme.backgroundColor1,
    minWidth: 50,
    minHeight: 30,
  },
  link: {
    color: theme.fontColor4,
  }
}

const List = Radium(({style, children}) => <div style={[Style.list, style]}>{children}</div>)
const ListItem = ({children}) => <div style={Style.listItem}>{children}</div>

const SelectProofOfIdentity = ({start}) => {
  const [consent, setConsent] = useState(false)
  const [files, setFiles] = useState([])

  return (
    <div style={Style.container}>
      <div style={themeStyle.sectionTitle}>Select proof of identity</div>
      <List>
        <ListItem>Select an image (image/jpeg) of a ID Book or ID Card</ListItem>
      </List>

      <FlexBox>
        <FlexBox  column marginRight>
          <img height={200} src={idBook} alt="id-book"/>
        </FlexBox>
        <FlexBox column centered>
          <img height={180} src={idCard} alt="id-card"/>
        </FlexBox>
      </FlexBox>

      <FlexBox marginBottom>
        <AcceptTermsAndConditions setConsent={setConsent}/>
      </FlexBox>

      <div style={Style.buttonBar}>
        <FileSelector onChange={(e) => {
          setFiles(files.concat([...e.target.files].map(f => f))); // convert into a real array
        }}/>
        <Button text="Start"
                disabled={files.length === 0 || !consent}
                onClick={() => {
                  const data = new FormData();
                  data.append("file", files[0]);
                  start(data)
                }}/>
      </div>

      <div style={Style.fileList}>
        {
          files.length > 0 ?
            files.map((f, i) =>
              <div style={Style.fileItem} key={`fileItem-${i}`}>
                <ImageFromFile file={f}/>
                <X style={Style.fileItemRemove} onClick={() => setFiles(files.filter((_, j) => j !== i))}/>
              </div>
            ) : <React.Fragment>
              <div style={Style.fileItem}>
                <div style={Style.placeholder1}/>
                <div style={Style.placeholder2}/>
              </div>
            </React.Fragment>
        }
      </div>
    </div>
  )
}

export default SelectProofOfIdentity
