import React from "react";
import Radium from "radium";
import TitleValueCard from "./TitleValueCard";

const Style = {
  container: {
    display: "grid",
    gridColumnGap: 10,
    gridRowGap: 20,
    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
  },
};

export default Radium(({gridArea = "content", data, large, small, centered, right}) => {
  return (
    <div style={[Style.container, {gridArea}]}>
      {
        data.map((d,i) => <TitleValueCard key={`${i}-title-value-card`} {...d} {...{large, small, centered, right}} />)
      }
    </div>
  )
});
