import React from "react";
import PropTypes from "prop-types";
import {theme} from "../../../theme/theme";
import {PlusCircle} from "react-feather";

const uploadFilesStyle = {
  hideFileInput: {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1
  },
  button: {
    display: "grid",
    gridGap: 10,
    alignItems: "center",
    gridTemplateColumns: "auto auto",
    color: theme.linkColor,
    fontWeight: 600,
    backgroundColor: theme.backgroundColor5,
    minWidth: 200,
    minHeight: 40,
    borderRadius: 15,
    cursor: "pointer"
  },
  icon: {
    justifySelf: "end",
  },
  text: {
    justifySelf: "start",
  }
};

const UploadFiles = ({text, onFileUploaded}) => {
  return (
    <div>
      <input id={"fileSelector"} style={uploadFilesStyle.hideFileInput}
             type="file"
             onChange={onFileUploaded}
             accept="application/pdf"
             multiple/>
      <label style={uploadFilesStyle.button} htmlFor={"fileSelector"}>
        <PlusCircle style={uploadFilesStyle.icon}/>
        <div style={uploadFilesStyle.text}>{text || "Select files"}</div>
      </label>
    </div>
  )
};

UploadFiles.propTypes = {
  text: PropTypes.string,
  onFileUploaded: PropTypes.func.isRequired
};

export default UploadFiles;
