import React from "react";
import FlexBox from "./FlexBox";
import { HelpCircle } from "react-feather";
import logo from "./sprintHive-logo-white.svg";
import {theme} from "theme/theme";
import {withRouter} from "react-router";
import ConnectButton from "components/button/ConnectButton"

export default withRouter(({history, header, children}) => {
  return (
    <FlexBox style={{color: theme.fontColor1, minHeight: "85vh"}} column vCenter>
      <FlexBox style={{maxWidth: 800, width: "100%"}} spaceBetween vCenter>
        <FlexBox marginLeft marginTop onClick={() => history.push("/")}>
          <img src={logo} width={180} height={75} alt="SprintHive logo" />
        </FlexBox>
        <FlexBox marginRight vCenter>
          { process.env.NODE_ENV === 'development'
            && <HelpCircle style={{marginRight: 10}} onClick={() => history.push("/storyboard")}/>
          }
          <ConnectButton/>
        </FlexBox>
      </FlexBox>
      <FlexBox style={{maxWidth: 600}} column flexGrow vCenter>
        {header}
        {children}
      </FlexBox>
    </FlexBox>
  )
})
