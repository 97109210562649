import React, {useState} from "react"
import MobileLayout from "components/layout/MobileLayout"
import FlexBox from "components/layout/FlexBox"
import Button from "components/button/Button"
import AcceptTermsAndConditions from "components/AcceptTermsAndConditions"

const Intro = ({history}) => {
  const [consent, setConsent] = useState(false)

  return (
    <MobileLayout>
      <FlexBox hMargin column>
        <p><b>Welcome to the SprintHive Identity Verification system.</b></p>
        <p>
          By going through this short journey, you will be helping us to solve the problem of
          identity fraud and identity theft - and make it easier for people to apply for financial products online.
        </p>
        <p>
          <b>Please read the following carefully:</b>
        </p>
        <ul>
          <li>There will be 3 steps to complete the journey, taking a total of 2-3 minutes of your time</li>
          <li>You will be asked to submit some personal information</li>
          <li>Before starting, please have the following ready:
            <ul>
              <li>A selfie camera or webcam on the device you are using</li>
              <li>Your green barcoded South African ID book</li>
              OR
              <li>Your South African smart ID card</li>
            </ul>
          </li>
        </ul>

        <FlexBox hCenter>
          <AcceptTermsAndConditions setConsent={setConsent}/>
        </FlexBox>
        <FlexBox hCenter style={{marginTop: 30, marginBottom: 50}}>
          <Button disabled={!consent} text="Let's get started!" onClick={() => {
            history.push("/idv/idDocument")
          }}/>
        </FlexBox>
      </FlexBox>
    </MobileLayout>
  )
}

export default Intro
