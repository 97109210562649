import React from "react";
import {Clock, FilePlus, PieChart} from "react-feather";
import Banner from "../../../../components/banner/Banner";

const IncomeBanner = ({selectedIndex}) =>
  <Banner items={[
    {icon: <FilePlus/>,  title: "Select files", selected: selectedIndex === 0},
    {icon: <Clock/>,  title: "Crunch the numbers", selected: selectedIndex === 1},
    {icon: <PieChart/>,  title: "View the results", selected: selectedIndex === 2},
  ]}/>

export default IncomeBanner
