import React from "react";
import {Redirect, Route, Switch, withRouter} from "react-router";
import ProductOverview from "./pages/services/Services";
import BankStatement from "./pages/incomeVerification/bankstatement/pages/BankStatement";
import UploadPayslip from "./pages/incomeVerification/payslip/pages/Payslip";
import Yodlee from "./pages/incomeVerification/yodlee/pages/Yodlee";
import SelectIdentity from "./pages/identity/verification/SelectIdentity";
import IdentityVerificationResults from "./pages/identity/verification/IdentityVerificationResults";
import CaptureSelfHeadshot from "./pages/identity/verification/CaptureSelfHeadshot";
import CaptureSelfHeadshotResults from "./pages/identity/verification/CaptureSelfHeadshotResults";
import CaptureProofOfPresence from "./pages/identity/verification/CaptureProofOfPresence";
import CaptureProofOfPresenceResults from "./pages/identity/verification/CaptureProofOfPresenceResults";

export default withRouter(() => {
  return (
    <React.Fragment>
      <Switch>
        <Route path="/" exact component={ProductOverview} />

        <Route path="/inv/bankstatement" exact component={BankStatement} />
        <Route path="/inv/payslip/upload" exact component={UploadPayslip} />
        <Route path="/inv/yodlee" exact component={Yodlee} />

        <Route path="/idv/identityVerification/selectIdentity" exact component={SelectIdentity} />
        <Route path="/idv/identityVerification/identityResults" exact component={IdentityVerificationResults} />
        <Route path="/idv/identityVerification/selfie" exact component={CaptureSelfHeadshot} />
        <Route path="/idv/identityVerification/SelfieResults" exact component={CaptureSelfHeadshotResults} />
        <Route path="/idv/identityVerification/presence" exact component={CaptureProofOfPresence} />
        <Route path="/idv/identityVerification/popResults" exact component={CaptureProofOfPresenceResults} />
        <Route>
          <Redirect to={"/"}/>
        </Route>
      </Switch>

    </React.Fragment>
  )
})
