import React from "react";
import {theme} from "../../theme/theme";

const Style = {
  card: {
    display: "grid",
    gridRowGap: 10,
    gridTemplateColumns: "repeat(1, auto)",
    gridTemplateRows: "auto 1fr",
    justifyItems: "center",
    minWidth: 200,
    color: theme.fontColor4,
    fontSize: 12,
  },
};


const ImageTitleCard = ({title="a title goes here",
                          alt="a thumbnail",
                          imageUrl = "https://via.placeholder.com/150x100",
                          width = 100}) => {
  return (
    <div style={Style.card}>
      <div>{title}</div>
      <img width={width} src={imageUrl} alt={alt}/>
    </div>
  )
};

export default ImageTitleCard;
