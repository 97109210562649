import React, {useRef, useEffect} from "react";
import * as d3 from "d3";
import moment from "moment";

const useDrawBars = (containerRef, data, xScale, yScale, heightLessMargin, setItemSelected) => {

  useEffect(() => {
    const selection = d3.select(containerRef.current);
    const x = selection
      .selectAll('rect')
      .data(data, d => d.key);

    const enter = x.enter();

    enter
      .append('rect')
      .style('fill', "#8d2b30")
      .style('cursor', "pointer")
      .attr("x", d => {
        const startOfMonthPlusOneHour = moment(d.key).startOf('day').add(1, 'hour').toDate()
        return xScale(startOfMonthPlusOneHour)
      })
      .attr('y', heightLessMargin)
      .attr('height', 0)
      .attr('width', (d) => {
        let startXPosition, endXPosition;
        const startOfMonthPlusOneHour = moment(d.key).startOf('day').add(1, 'hour').toDate();
        startXPosition = xScale(startOfMonthPlusOneHour);
        const endOfMonthLessOneHour = moment(d.key).endOf('day').subtract(13, 'hour').toDate();
        endXPosition = xScale(endOfMonthLessOneHour);
        return endXPosition - startXPosition;
      })
      .on("mouseover", (d) => {
        setItemSelected && setItemSelected(d);
      })
      .transition()
      .duration(300)
      .attr('y', function (d) {
        return yScale(d.debit);
      })
      .attr('height', function (d) {
        return heightLessMargin - yScale(d.debit);
      });

    enter
      .append('rect')
      .style('fill', "#5f9f63")
      .style('cursor', "pointer")
      .attr("x", d => {
        const startOfMonthPlusOneHour = moment(d.key).startOf('day').add(10, 'hour').toDate()
        return xScale(startOfMonthPlusOneHour)
      })
      .attr('y', heightLessMargin)
      .attr('height', 0)
      .attr('width', (d) => {
        let startXPosition, endXPosition;
        const startOfMonthPlusOneHour = moment(d.key).startOf('day').add(14, 'hour').toDate();
        startXPosition = xScale(startOfMonthPlusOneHour);
        const endOfMonthLessOneHour = moment(d.key).endOf('day').subtract(1, 'hour').toDate();
        endXPosition = xScale(endOfMonthLessOneHour);
        return endXPosition - startXPosition;
      })
      .on("mouseover", (d) => {
        setItemSelected && setItemSelected(d);
      })
      .transition()
      .duration(300)
      .attr('y', function (d) {
        return yScale(d.credit);
      })
      .attr('height', function (d) {
        return heightLessMargin - yScale(d.credit);
      });

/*
    x.transition()
      .duration(300)
      .attr('y', function (d) {
        return yScale(d.value);
      })
      .attr('height', function (d) {
        return heightLessMargin - yScale(d.value);
      })
*/

  }, [data, heightLessMargin, xScale, yScale, containerRef, setItemSelected]);
};

const Bars = props => {
  const barsRef = useRef(null);
  const {widthLessMargin, heightLessMargin, data, xScale, yScale, setItemSelected} = props;

  useDrawBars(barsRef, data, xScale, yScale, heightLessMargin, setItemSelected);

  return (
    <g ref={barsRef}
       width={widthLessMargin}
       height={heightLessMargin}/>
  )
}

export default Bars
