import React from "react"
import ImageTitleCard from "components/cards/ImageTitleCard"
import {List, ListItem} from "components/list/List"
import errorMapper from "../verification/ErrorMapper"

const Style = {
  selfie: {
    gridArea: "selfie",
    display: "grid",
    gridColumnGap: 10,
    gridRowGap: 20,
    alignItems: "start",
    justifyItems: "start",
    gridTemplateColumns: "220px 1fr",
  },
}

const SelfieView = ({status, providedImageUrl, errorCodes}) => {
  const title = "Self headshot"
  if (status === "not-started") {
    return (
      <div style={{gridArea: "selfie"}}>
        <div>{title} has not been started</div>
      </div>
    )
  } else if (status === "failed") {
    return (
      <div style={Style.selfie}>
        {  providedImageUrl && <ImageTitleCard imageUrl={providedImageUrl} title={title} width={200}/>}
        <List style={{marginTop: 20}}>
          <ListItem>{title} has been unsuccessfully captured</ListItem>
          {
            errorCodes && errorCodes.map(errorCode => <ListItem key={errorCode}>{errorMapper(errorCode)}</ListItem>)
          }
        </List>
      </div>
    )
  } else if (status === "captured") {
    return (
      <div style={Style.selfie}>
        {  providedImageUrl && <ImageTitleCard imageUrl={providedImageUrl} title={title} width={200}/>}
        <List style={{marginTop: 20}}>
          <ListItem>{title} has been successfully captured</ListItem>
        </List>
      </div>
    )
  }
}

export default SelfieView
