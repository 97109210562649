import React, {useCallback, useState} from 'react'
import {Check, X} from "react-feather";
import {theme} from "../../../../theme/theme";
import MainLayout from "../../../../components/layout/MainLayout";
import Banner from "../../components/banner/Banner";
import TitleValueCard from "../../../../components/cards/TitleValueCard";
import ImageButton from "../../../../components/imageButton/ImageButton";
import PerDayChart from "../../../../components/charts/perDayChart/PerDayChart";
import moment from "moment";
import TitleValueSummaryCard from "../../../../components/cards/TitleValueSummaryCard";
import DayOfWeekChart from "../../../../components/charts/dayOfWeekChart/DayOfWeekChart";
import useTransactionSummaryReducer from "../../../../hooks/useTransactionSummaryReducer";
import TransactionList from "../../../../components/transactionList/TransactionList";
import {withRouter} from "react-router";
import noneIfNull from "../../../../lib/noneIfNull";
import uuid from "../../../../lib/uuid";

const Style = {
  container: {
    display: "grid",
    gridGap: 20,
    gridTemplateAreas: `
      "banner         banner          banner"
      "incomeResult   nameAndAddress  feedback"
      "incomeResult   bankDetails     feedback"
      "transactions   transactions    transactions"
    `,
  },
  banner: {
    gridArea: "banner",
  },
  incomeResult: {
    gridArea: "incomeResult",
    marginLeft: 10,
  },
  nameAndAddress: {
    gridArea: "nameAndAddress",
    display: "grid",
    gridGap: 10,
    justifyItems: "start",
    gridTemplateColumns: "auto 1fr",
  },
  feedback: {
    gridArea: "feedback",
    marginRight: 10,
    display: "grid",
    gridGap: 10,
    gridTemplateRows: "auto auto 1fr",
  },
  bankDetails: {
    gridArea: "bankDetails",
    display: "grid",
    gridGap: 10,
    gridTemplateColumns: "auto 1fr",
  },
  transactions: {
    gridArea: "transactions",
    marginRight: 10,
    marginLeft: 10,
    display: "grid",
    gridTemplateColumns: "1fr 400px",
    gridTemplateAreas: `
      "transactionTitle    transactionTitle"
      "perDayChart         perDayChart"
      "transactionSummary  dayOfWeekChart"
      "transactionsList    transactionsList"
    `,
  },
  transactionTitle: {
    gridArea: "transactionTitle",
    borderTop: `solid 1px ${theme.borderColor1}`,
    textAlign: "right",
    paddingTop: 5,

    display: "grid",
    gridRowGap: 5,
    gridColumnGap: 10,
    gridTemplateRows: "15px",
    gridTemplateColumns: "auto auto auto 1fr",
    minHeight: 20,
    fontSize: 12,
  },
  debit: {
    color: "#8d2b30",
    fontWeight: 600
  },
  credit: {
    color: "#5f9f63",
    fontWeight: 600
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 600,
    justifySelf: "end"
  },
  perDayChart: {
    gridArea: "perDayChart",
  },
  transactionSummary: {
    gridArea: "transactionSummary",
    marginLeft: 10,

    display: "grid",
    gridGap: 10,
    justifyItems: "center",
    alignItems: "start",
    gridTemplateColumns: "repeat(3, auto)",
    gridTemplateRows: "repeat(3, auto)",
  },
  dayOfWeekChart: {
    gridArea: "dayOfWeekChart",
  },
  transactionsList: {
    gridArea: "transactionsList",
    paddingTop: 10,
    paddingBottom: 10,
  },
};

const IncomeResult = ({estimatedIncome}) =>
  <div style={Style.incomeResult}>
    <TitleValueCard title="Estimated income" large value={noneIfNull(estimatedIncome)}/>
  </div>;

const NameAndAddress = ({name, address}) =>
  <div style={Style.nameAndAddress}>
    <TitleValueCard title="Name" value={name}/>
    <TitleValueCard title="Address" value={address}/>
  </div>;

const BankDetails = ({accountType, accountNumber, bank}) =>
  <div style={Style.bankDetails}>
    <TitleValueCard title="Bank" value={bank}/>
    <TitleValueCard title="Account type" value={accountType}/>
    <TitleValueCard title="Account number" value={accountNumber}/>
  </div>;

const Feedback = ({onRightClicked, onWrongClicked}) =>
  <div style={Style.feedback}>
    <ImageButton text="Right" icon={<Check/>} onClick={onRightClicked}/>
    <ImageButton text="Wrong" icon={<X/>} onClick={onWrongClicked}/>
  </div>;

const TransactionTitle = ({selectedItem}) => {
  return selectedItem
    ? <div style={Style.transactionTitle}>
      <div>Date of transaction {moment(selectedItem.date, "YYYY-MM-DD").format("DD ddd MMM YYYY")}</div>
      <div>Debit <span style={Style.debit}>{selectedItem.debit}</span></div>
      <div>Credit <span style={Style.credit}>{selectedItem.credit}</span></div>
      <div style={Style.sectionTitle}>TRANSACTIONS</div>
    </div>
    :
    <div style={Style.transactionTitle}>
      <div/>
      <div/>
      <div/>
      <div style={Style.sectionTitle}>TRANSACTIONS</div>
    </div>
};

const TransactionSummary = ({
                              statementStartDate,
                              statementEndDate,
                              numberOfDebits,
                              numberOfCredits,
                              creditAmount,
                              debitAmount,
                              duration,
                              numberOfTransactions
                            }) => {
  return (
    <React.Fragment>
      <TitleValueSummaryCard title="Start date" value={statementStartDate}/>
      <TitleValueSummaryCard title="End date" value={statementEndDate}/>
      <TitleValueSummaryCard title="Duration" value={duration}/>

      <TitleValueSummaryCard title="Total credit amount" value={creditAmount} credit/>
      <TitleValueSummaryCard title="Number of credits" value={numberOfCredits} credit right/>
      <TitleValueSummaryCard title="Number of transactions" value={numberOfTransactions} right/>

      <TitleValueSummaryCard title="Total debit amount" value={debitAmount} debit/>
      <TitleValueSummaryCard title="Number of debits" value={numberOfDebits} debit right/>
    </React.Fragment>
  )
};

const Transactions = ({transactions}) => {
  const [itemSelected, setItemSelected] = useState(undefined);
  const onItemSelected = useCallback(item => setItemSelected(item), []);
  const summaryData = useTransactionSummaryReducer(transactions);

  // add a transactionId to every transaction used for calculating the selected amount
  const transactionsWithIds = transactions.map(t => {
    t.transactionId = uuid();
    return t;
  });

  return (
    <div style={Style.transactions}>
      <TransactionTitle selectedItem={itemSelected}/>
      <div style={Style.perDayChart}>
        <PerDayChart transactions={transactions} setItemSelected={onItemSelected}/>
      </div>
      <div style={Style.transactionSummary}>
        {summaryData ? <TransactionSummary {...summaryData}/> : null}
      </div>
      <div style={Style.dayOfWeekChart}>
        <DayOfWeekChart {...{transactions}}/>
      </div>
      <div style={Style.transactionsList}>
        <TransactionList transactions={transactionsWithIds}/>
      </div>
    </div>
  );
};

export default withRouter(({estimatedIncome, transactions, history}) =>
  <MainLayout>
    <div style={Style.container}>
      <Banner selectedIndex={2}/>
      <IncomeResult estimatedIncome={estimatedIncome}/>
      <NameAndAddress name={"Thomas Bayes"} address={"SprintHive (pty) Ltd 2 Fir Street Observatory Cape Town - 7925"}/>
      <BankDetails bank={"Standard Bank"} accountType={"Savings"} accountNumber={"1234567890"}/>
      <Transactions transactions={transactions}/>
      <Feedback
        onRightClicked={() => {
          history.push("/");
        }}
        onWrongClicked={() => {
          history.push("/");
        }}
      />
    </div>
  </MainLayout>);
