import React from "react";
import Radium from "radium";
import {theme} from "../../theme/theme";

const Style = {
  container: {
    display: "grid",
    alignContent: "center",
    marginBottom: 5,
    borderRadius: 15,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: "center",
    cursor: "pointer",
    backgroundColor: theme.backgroundColor3,
    color: theme.fontColor4,
    userSelect: "none",
    fontSize: 12,
  },
  selected: {
    backgroundColor: theme.backgroundColor5,
    color: theme.linkColor,
  }
};

export default Radium(props => {

  const {children, selected, onMouseEnter, onMouseLeave, onClick, style} = props;

  return (
    <div style={[Style.container, selected && Style.selected, style]}
         onMouseEnter={onMouseEnter}
         onMouseLeave={onMouseLeave}
         onClick={onClick}>{children}</div>
  )
});
