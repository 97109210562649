import React, {useState} from 'react'
import Radium from "radium";
import {X} from "react-feather";
import {theme, themeStyle} from "theme/theme";
import BannerLayout from "components/layout/BannerLayout";
import Button from "components/button/Button";
import ImageFromPdf from "components/imageFromFile/ImageFromPdf";
import {usePayslipContext} from "../PayslipContext";
import UploadFiles from "../../components/UploadFiles";
import Banner from "../../components/banner/Banner";
import AcceptTermsAndConditions from "components/AcceptTermsAndConditions"
import FlexBox from "components/layout/FlexBox"

const Style = {
  main: {
    gridArea: "content",
    display: "grid",
    gridGap: 10,

    marginLeft: 40,
    marginRight: 40,
    justifyItems: "center",
    alignItems: "start",
    gridTemplateRows: "auto auto auto auto 1fr",
  },
  list: {
    display: "grid",
    gridRowGap: 5,
    gridTemplateRows: "repeat(4,auto)",

  },
  listItem: {
    flex: 1,
    color: theme.fontColor4,
  },
  fileList: {
    marginTop: 30,
    display: "grid",
    gridGap: 10,
    color: theme.fontColor4,
  },
  fileItem: {
    display: "grid",
    gridGap: 10,
    gridTemplateColumns: "auto auto",
    gridTemplateRows: "auto",
    alignItems: "center",
    ":hover": {
      backgroundColor: "rgba(0,0,0,0.1)"
    },
  },
  fileItemRemove: {
    alignSelf: "start",
    cursor: "pointer"
  },
  buttonBar: {
    display: "grid",
    gridGap: 10,
    gridTemplateColumns: "auto auto",
    gridTemplateRows: "auto"
  },
  placeholder1: {
    backgroundColor: theme.backgroundColor1,
    minWidth: 300,
    height: "100%"
  },
  placeholder2: {
    backgroundColor: theme.backgroundColor1,
    minWidth: 50,
    minHeight: 30,
  }
};

const List = Radium(({style, children}) => <div style={[Style.list, style]}>{children}</div>);
const ListItem = ({children}) => <div style={Style.listItem}>{children}</div>;

const SelectPayslip = () => {
  const {startBatch} = usePayslipContext();
  const [files, setFiles] = useState([]);
  const [consent, setConsent] = useState(false)

  return (
    <BannerLayout banner={<Banner selectedIndex={0}/>}>
      <div style={Style.main}>
        <div style={themeStyle.sectionTitle}>Select a payslip</div>

        <List>
          <ListItem>Select a PDF payslip and click start</ListItem>
        </List>

        <FlexBox marginBottom>
          <AcceptTermsAndConditions setConsent={setConsent}/>
        </FlexBox>

        <div style={Style.buttonBar}>
          <UploadFiles onFileUploaded={(e) => {
            setFiles(files.concat([...e.target.files].map(f => f))); // convert into a real array
          }}/>
          <Button text="Start" disabled={files.length === 0 || !consent} onClick={() => startBatch.call(files)}/>
        </div>

        <div style={Style.fileList}>
          {
            files.length > 0 ?
              files.map((f,i) =>
                <div style={Style.fileItem} key={`fileItem-${i}`}>
                  <ImageFromPdf file={f}>
                    <div>{f.name.substr(0, 75)}</div>
                  </ImageFromPdf>
                  <X style={Style.fileItemRemove} onClick={() => setFiles(files.filter((_, j) => j !== i))}/>
                </div>
              ) : <React.Fragment>
                <div style={Style.fileItem}>
                  <div style={Style.placeholder1}/>
                  <div style={Style.placeholder2}/>
                </div>
              </React.Fragment>
          }
        </div>
      </div>
    </BannerLayout>
  )
};

export default SelectPayslip;
