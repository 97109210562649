import React from "react";
import MainLayout from "./MainLayout";

const Style = {
  container: {
    display: "grid",
    gridRowGap: 20,
    minHeight: "100%",
    gridTemplateRows: "auto 1fr",
    gridTemplateAreas: `
      "banner"
      "content"
    `,
  },
  banner: {
    gridArea: "banner",
  },
  content: {
    gridArea: "content",
  },
};

const BannerLayout = ({banner, children}) => {
  return (
    <MainLayout>
      <div style={Style.container}>
        {banner}
        {children}
      </div>
    </MainLayout>
  )
};

export default BannerLayout
