import React from "react";
import Radium from "radium";
import {theme} from "../../../theme/theme";
import ImageTitleCard from "../../../components/cards/ImageTitleCard";

const Style = {
  images: {
    gridArea: "images",
    display: "grid",
    gridGap: 20,
    paddingTop: 10,
    paddingBottom: 10,
    justifyItems: "center",
    alignItems: "start",
    backgroundColor: theme.backgroundColor3,
  },
};

export default Radium(({titleUrls}) => {
  return (
    <div style={[Style.images, {gridTemplateRows: `repeat(${titleUrls.length -1}, auto) 1fr`}]}>
      {
        titleUrls
          .map((d, i) => <ImageTitleCard key={`${i}-titleImage`} {...d}/>)
      }
    </div>
  )
});
