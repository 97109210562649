import React, {useEffect, useState} from "react";
import {AlertTriangle} from "react-feather";
import {estimateIncome, getUserToken} from "../api";
import MainLayout from "../../../../components/layout/MainLayout";
import {useScript} from "../../../../hooks/useScript";
import LoadingCounter from "../../../../components/loading/LoadingCounter";
import FlexBox from "../../../../components/layout/FlexBox";
import ViewResult from "./ViewResult";
import {theme} from "../../../../theme/theme";
import Banner from "../../components/banner/Banner";
import {useFastLinkReSizer} from "../useFastLinkReSizer";
import {get} from "../../../../lib/get";

const Style = {
  container: {
    display: "grid",
    gridRowGap: 20,
    minHeight: "100%",
    gridTemplateRows: "auto 1fr",
    gridTemplateAreas: `
      "banner"
      "content"
    `,
  },
  banner: {
    gridArea: "banner",
  },
  content: {
    gridArea: "content",
  },
  message: {
    color: theme.fontColor4,
    marginTop: 10,
    fontSize: 24,
  }
};

const BannerLayout = ({selectedIndex = 0, children}) => {
  return (
    <div style={Style.container}>
      <Banner selectedIndex={selectedIndex}/>
      {children}
    </div>
  )
};

const useUserToken = () => {
  const [userToken, setUserToken] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    getUserToken()
      .then(res => {
        setUserToken(res.data.userToken);
      })
      .catch(error => {
        console.error("Error getting user token", error);
        setError(error);
      })
      .finally(() => setLoading(false));
  }, []);

  return [userToken, loading, error];
};

const useEstimatedIncome = (userToken) => {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    let subscription;
    if (userToken) {
      subscription = estimateIncome({userToken})
        .subscribe(
          data => {
            setData(data);
            setLoading(false);
          },
          error => {
            const errorType = get(["response", "data", "error", "errorType"], error, "unknown");
            const errorMessage = errorType === "insufficient-transaction-history"
              ? "There is not enough transaction history to estimate your income"
              : "There has been an error processing your transaction history";
            setError(errorMessage);
            setLoading(false);
          }
        );
    }
    return () => subscription && subscription.unsubscribe();
  }, [userToken]);

  console.log(data, error)

  return [data, loading, error];
};

const Loading = ({message, selectedIndex = 0}) =>
  <MainLayout>
    <BannerLayout selectedIndex={selectedIndex}>
      <div style={Style.content}>
        <FlexBox style={{minHeight: "100%"}} column centered>
          <div style={{maxWidth: 200}}>
            <LoadingCounter percentComplete={0}/>
          </div>
          <div style={Style.message}>{message}</div>
        </FlexBox>
      </div>
    </BannerLayout>
  </MainLayout>;

const ErrorView = ({message}) =>
  <MainLayout>
    <FlexBox style={{minHeight: "100%"}} column centered>
      <AlertTriangle width={72} height={72}/>
      <div style={Style.message}>{message}</div>
    </FlexBox>
  </MainLayout>;

export const LoadYodlee = () => {
  const [loaded, error] = useScript("https://cdn.yodlee.com/fastlink/v1/initialize.js");

  return !loaded
    ? <Loading message="Loading Yodlee"/>
    : <ViewOrError error={error} message="There has been an error loading the yodlee fast link module.">
      <GetUserToken/>
    </ViewOrError>;
};

const GetUserToken = () => {
  const [userToken, loading, error] = useUserToken();
  const [fastLinkData, setFastLinkData] = useState(undefined);

  if (fastLinkData) return <ViewResultContainer userToken={userToken}/>;

  return loading ? <Loading message={"Creating a Yodlee user token"}/>
    : <ViewOrError error={error} message="Could not create a user token">
      <YodleeFastLink userToken={userToken} setFastLinkData={setFastLinkData}/>
    </ViewOrError>
};

const ViewOrError = ({error, message, children}) => !error ? children : <ErrorView message={message}/>;

const ViewResultContainer = ({userToken}) => {
  const [data = {}, loading, error] = useEstimatedIncome(userToken);
  return loading
    ? <Loading message="Processing Yodlee transaction history" selectedIndex={1}/>
    : <ViewOrError error={error} message={error}>
      <ViewResult estimatedIncome={data.estimatedMonthlyIncome} transactions={data.transactions}/>
    </ViewOrError>
};

const YodleeFastLink = ({userToken, setFastLinkData}) => {
  useFastLinkReSizer();
  useEffect(() => {
    window.fastlink.open({
      fastLinkURL: 'https://node.sandbox.yodlee.com/authenticate/restserver',
      jwtToken: `Bearer ${userToken}`,
      onSuccess: function (data) {
        console.log("onSuccess", data);
        setFastLinkData(data);
      },
      onError: function (data) {
        console.log("onError", data);
      },
      onExit: function (data) {
        console.log("onExit", data);
      },
      onEvent: function (data) {
        console.log("onEvent", data);
        const element = document.querySelector("#container-fastlink #main-container");
        if (element) element.style.backgroundColor = theme.backgroundColor2;
      }
    }, "container-fastlink")
  }, [userToken, setFastLinkData]);

  return (
    <MainLayout>
      <BannerLayout selectedIndex={0}>
        <div style={Style.content}>
          <div id="container-fastlink" style={{minWidth: "80vw"}}/>
        </div>
      </BannerLayout>
    </MainLayout>
  )
};

export default LoadYodlee
