import React, {useState, useCallback} from "react"
import MobileLayout from "components/layout/MobileLayout";
import FlexBox from "components/layout/FlexBox";
import {identityEndpoint, useApiKey} from "AppConfig";
import {useUpload} from "hooks/useServerCall";
import Loading from "mobile/pages/identity/components/Loading";
import CaptureImage from "mobile/pages/identity/components/CaptureImage";
import Confirmation from "mobile/pages/identity/components/Confirmation";
import ErrorHandler from "mobile/pages/identity/components/ErrorHandler";
import Header from "../components/Header";
import {get} from "lib/get";
import idBookSample from "./idbook.png"
import idCardSample from "./idcard.png"

const IdentityDocument = ({history}) => {
  const apiKey = useApiKey();
  const url = `${identityEndpoint}/identityVerification/sync`;
  const [data, loading, error, start, clearError, percentCompleted] = useUpload({url, apiKey});
  const [fileErrors, setFileErrors] = useState(undefined)
  const errors = get(["response", "data", "error", "errors"], error, []);
  const errorCodes = errors.map(({errorCode}) => errorCode)

  if (fileErrors) {
    errorCodes.push(fileErrors)
  }

  if (error && errorCodes.length === 0) {
    // there was a general error
    errorCodes.push(error.message)
  }

  const resetErrors = useCallback(() => {
    clearError()
    setFileErrors(undefined)
  }, [clearError, setFileErrors])

  return <IdentityRouter {...{history, data, loading, errorCodes, start, resetErrors, percentCompleted, setFileErrors}}/>
}

export default IdentityDocument

const IdentityRouter = ({history, data, loading, errorCodes = [], start, resetErrors, percentCompleted, setFileErrors}) => {
  let view
  if (loading) {
    const loadingMessage = percentCompleted < 100 ? "Uploading..." : "Extracting identity data...";
    view = <Loading {...{loadingMessage}}/>
  } else if (errorCodes.length) {
    view = <ErrorHandler {...{resetErrors, errorCodes}}/>
  } else if (!data) {
    view = <CaptureIdentity {...{start, setFileErrors}}/>
  } else {
    view = <Confirmation message={<p>Thank you!<br/>That was a quality image and we<br/>managed to read your document</p>}
                         done={() => history.push("/idv/selfie", data)}/>
  }

  return (
    <MobileLayout header={<Header title="ID Document" step={1}/>}>
      {view}
    </MobileLayout>
  )
}

export const IdentityDocumentDemo = () => {
  return (
    <FlexBox column marginBottom>
      <FlexBox hCenter>
        <h1>Loading with different message</h1>
      </FlexBox>
      <IdentityRouter loading={true} percentCompleted={1}/>
      <IdentityRouter loading={true} percentCompleted={100}/>
      <FlexBox hCenter>
        <h1>Test error handling</h1>
      </FlexBox>
      <IdentityRouter error={{}}/>
      <IdentityRouter error={{response: {data: {identityDocument: {errorCodes: ["blurred-image"]}}}}}/>
      <IdentityRouter error={{response: {data: {identityDocument: {errorCodes: ["blurred-image"]}}}}}
                      clearError={() => console.log("Retrying...")}/>
      <FlexBox hCenter>
        <h1>Capture Identity</h1>
      </FlexBox>
      <IdentityRouter/>
      <FlexBox hCenter>
        <h1>Confirmation</h1>
      </FlexBox>
      <IdentityRouter data={{}}/>
    </FlexBox>
  )
}

const CaptureIdentity = ({start, setFileErrors}) => {
  return (
    <FlexBox column flexGrow vCenter>
      <StockImages/>
      <Instructions/>
      <FlexBox>
        <CaptureImage onChange={file => {
          const data = new FormData();
          data.append("file", file);
          start(data)
        }} onError={setFileErrors}/>
      </FlexBox>
    </FlexBox>
  )
}

const StockImages = () => {
  return (
    <FlexBox marginBottom marginTop>
      <FlexBox marginRight style={{minWidth: 125, minHeight: 75}} centered>
        <img src={idBookSample} width={125} alt="A sample identity book"/>
      </FlexBox>
      <FlexBox style={{minWidth: 125, minHeight: 75}} centered>
        <img src={idCardSample} height={100} alt="A sample identity card"/>
      </FlexBox>
    </FlexBox>
  )
}

const Instructions = () => {
  return (
    <FlexBox hMargin column>
      <p><b>Please take a photo of your ID document</b></p>
      <h4>Important Checks</h4>
      <ul>
        <li>The photo is in focus (hold the camera steady and if appropriate allow it to auto-focus)</li>
        <li>It is evenly lit (no reflected lights or shadows)</li>
        <li>It takes up most of the image</li>
      </ul>
    </FlexBox>
  )
}
