import React from "react";
import FlexBox from "components/layout/FlexBox";
import LoadingCounter from "components/loading/LoadingCounter";
import {theme} from "theme/theme";

const Style = {
  message1: {
    fontSize: 18,
    fontWeight: 400,
    marginTop: 10,
    color: theme.fontColor4,
  },
  message2: {
    fontSize: 14,
    fontWeight: 400,
    marginTop: 10,
    color: theme.fontColor4,
  }
}

const Loading = ({loadingMessage}) => {
  return (
    <FlexBox column centered flexGrow>
      <FlexBox style={{maxWidth: 200}}>
        <LoadingCounter/>
      </FlexBox>
      <FlexBox style={Style.message1}>{loadingMessage}</FlexBox>
      <FlexBox style={Style.message2}>(This may take a minute)</FlexBox>
    </FlexBox>
  )
}

export default Loading
