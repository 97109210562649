import React, {useState, useCallback} from "react"
import {identityEndpoint, useApiKey} from "AppConfig";
import {get} from "lib/get";
import {useUpload} from "hooks/useServerCall";
import MobileLayout from "components/layout/MobileLayout";
import FlexBox from "components/layout/FlexBox";
import CaptureImage from "mobile/pages/identity/components/CaptureImage";
import Header from "../components/Header";
import Loading from "mobile/pages/identity/components/Loading";
import ErrorHandler from "../components/ErrorHandler";
import {Redirect} from "react-router";
import PopSample from "./pop.png";
import {filterErrorCodes, filterFastForwardErrors} from "pages/identity/verification/ErrorMapper";

const Pop = ({history, location}) => {
  const [fileErrors, setFileErrors] = useState(undefined)
  const apiKey = useApiKey();
  const identityVerificationId = get(["state", "identityVerificationId"], location, undefined)
  const authToken = get(["state", "authToken"], location, undefined);
  const url = `${identityEndpoint}/identityVerification/sync/${identityVerificationId}/proofOfPresence`;
  const [data, loading, error, start, clearError, percentCompleted, failedAttempts] = useUpload({authToken, apiKey, url});
  const responseErrorCodes = get(["response", "data", "proofOfPresence", "errorCodes"], error, [])
  const errorCodes = filterErrorCodes(responseErrorCodes) // errors that must be displayed to the user
  const fastForwardErrorCodes = filterFastForwardErrors(responseErrorCodes) // errors that continue to the next step
  const maxFailedAttemptsReached = failedAttempts >= 3

  if (fileErrors) {
    errorCodes.push(fileErrors)
  }

  if (error && errorCodes.length === 0) {
    // there was a general error
    errorCodes.push(error.message)
  }

  const resetErrors = useCallback(() => {
    clearError()
    setFileErrors(undefined)
  }, [clearError, setFileErrors])

  return <PopRouter {...{history, authToken, identityVerificationId, data, loading, errorCodes, start, resetErrors,
    percentCompleted, maxFailedAttemptsReached, fastForwardErrorCodes, setFileErrors}}/>
}

export default Pop

const PopRouter = ({authToken, identityVerificationId, data, loading, errorCodes = [], start, resetErrors,
                     percentCompleted, maxFailedAttemptsReached, fastForwardErrorCodes = [], setFileErrors}) => {
  let view = null
  if (!authToken || !identityVerificationId) {
    // handle invalid state
    view = <InvalidState message="AuthToken and identityVerificationId is required"/>
  } else {
    if (loading) {
      const loadingMessage = percentCompleted < 100 ? "Uploading..." : "Extracting your smile...";
      view = <Loading {...{loadingMessage}}/>
    } else if (errorCodes && errorCodes.length > 0 && !maxFailedAttemptsReached) {
      view = <ErrorHandler {...{resetErrors, errorCodes}}/>
    } else if (!data && !maxFailedAttemptsReached && fastForwardErrorCodes.length === 0) {
      view = <CapturePop {...{start, setFileErrors}}/>
    } else {
      return <Redirect to={{pathname: "/idv/results", state: {identityVerificationId}}}/>
    }
  }

  return (
    <MobileLayout header={<Header title="Selfie with a smile" step={3}/>}>
      {view}
    </MobileLayout>
  )
}

export const PopDemo = () => {
  return (
    <FlexBox column marginBottom>

      <FlexBox hCenter>
        <h1>Invalid state</h1>
      </FlexBox>
      <PopRouter/>
      <FlexBox hCenter>
        <h1>Loading with different message</h1>
      </FlexBox>
      <PopRouter authToken={"a-token"} identityVerificationId={"some-id"} loading={true} percentCompleted={1}/>
      <PopRouter authToken={"a-token"} identityVerificationId={"some-id"} loading={true} percentCompleted={100}/>
      <FlexBox hCenter>
        <h1>Test error handling</h1>
      </FlexBox>
      <PopRouter authToken={"a-token"} identityVerificationId={"some-id"} error={{}}/>
      <PopRouter authToken={"a-token"} identityVerificationId={"some-id"} error={{response: {data: {selfie: {errorCodes: ["blurred-image"]}}}}}/>
      <PopRouter authToken={"a-token"} identityVerificationId={"some-id"} error={{response: {data: {selfie: {errorCodes: ["size-limit-exceeded"]}}}}}
                    clearError={() => console.log("Retrying...")}/>

      <FlexBox hCenter>
        <h1>Capture Selfie</h1>
      </FlexBox>
      <PopRouter authToken={"a-token"} identityVerificationId={"some-id"}/>
    </FlexBox>

  )
}

const InvalidState = ({message}) => {
  return (
    <FlexBox style={{textAlign: "center"}} margin centered flexGrow>
      {message}
    </FlexBox>
  )
}

const CapturePop = ({start, setFileErrors}) => {
  return (
    <FlexBox column flexGrow vCenter>
      <StockImage/>
      <Instructions/>
      <FlexBox>
        <CaptureImage onChange={file => {
          const data = new FormData();
          data.append("file", file);
          start(data)
        }} onError={setFileErrors}/>
      </FlexBox>
    </FlexBox>
  )
}

const StockImage = () => {
  return (
    <FlexBox marginBottom marginTop>
      <FlexBox style={{minWidth: 125, minHeight: 75}} centered>
        <img src={PopSample} width={125} alt="An example of a pop"/>
      </FlexBox>
    </FlexBox>
  )
}

const Instructions = () => {
  return (
    <FlexBox hMargin column>
      <p><b>Please take a selfie and SMILE!</b></p>
      <h4>Important Checks</h4>
      <ul>
        <li>You are smiling</li>
        <li>The selfie is in focus</li>
        <li>Your face isn’t in dark shadows in front of a bright background</li>
        <li>Your face is the only one in the photo</li>
        <li>Finally, take off your glasses</li>
      </ul>
    </FlexBox>
  )
}
