import React, {useRef, useEffect} from 'react'
import {Subject} from "rxjs";
import {theme} from "theme/theme";
import FlexBox from "components/layout/FlexBox";
import BannerLayout from "components/layout/BannerLayout";
import LoadingCounter from "components/loading/LoadingCounter";
import Banner from "../banner/Banner";

const Style = {
  loading: {
    gridArea: "content",

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  message: {
    fontSize: 18,
    fontWeight: 400,
    marginTop: 10,
    minWidth: 400,
    color: theme.fontColor4,
    textAlign: "center",
  }
};

const Loading = ({loadingMessage = "Loading..."}) => {
  const onResetCounter$ = useRef(new Subject());

  useEffect(() => {
    onResetCounter$.current.next("reset")
  }, [loadingMessage]);

  return (
    <BannerLayout banner={<Banner selectedIndex={1}/>}>
      <FlexBox style={Style.loading} centered>
        <div style={{maxWidth: 200}}>
          <LoadingCounter {...{onResetCounter$: onResetCounter$.current}}/>
        </div>
        <div style={Style.message}>{loadingMessage}</div>
      </FlexBox>
    </BannerLayout>
  )
};

export default Loading
