import React from "react";
import TitleValueCard from "../../../components/cards/TitleValueCard";
import {useBankStatementContext} from "../bankstatement/BankStatementContext";

const Style = {
  container: {
    display: "grid",
    gridGap: 10,
    justifyItems: "start",
    gridTemplateColumns: "auto 1fr",
    gridTemplateRows: "repeat(3, auto)",
  },
};

const NameAndAddress = ({name, address}) => {
  return (
    <div style={Style.container}>
      <TitleValueCard title="Name" value={name}/>
      <TitleValueCard title="Address" value={address}/>
    </div>
  );
};


const NameAndAddressContainer = () => {
  const {startBatch} = useBankStatementContext();
  const {name, address} = startBatch.data.extractedData;

  return (
    <NameAndAddress {...{name, address}}/>
  )
}

export default NameAndAddressContainer
