import React from "react";
import Radium from "radium";
import {theme} from "../../theme/theme";
import FlexBox from "../layout/FlexBox";

const bannerStyle = {
  banner: {
    gridArea: "banner",
    maxHeight: 60,
    display: "grid",
    gridGap: 10,
    backgroundColor: theme.backgroundColor3,
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "auto",
    justifyItems: "stretch",
    alignItems: "center",
    marginBottom: 20,
    color: theme.fontColor4,
  },
  bannerItem: {
    display: "grid",
    gridGap: 10,
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto",
    justifyItems: "center",
    alignItems: "center",
    borderBottom: `2px solid ${theme.borderColor1}`,
    userSelect: "none",
  },
  icon: {
    fontSize: 34,
    justifySelf: "end",
    marginRight: 10,
  },
  title: {
    fontSize: 18,
    justifySelf: "start",
  },
  selected: {
    borderBottom: `2px solid ${theme.linkColor}`
  },
};

const BannerItem = Radium(({icon, title, selected}) =>
  <div className="bannerItem" style={[bannerStyle.bannerItem, selected && bannerStyle.selected]}>
    <FlexBox centered>
      <div style={bannerStyle.icon}>{icon}</div>
      <div style={bannerStyle.title}>{title}</div>
    </FlexBox>
  </div>
);

export default Radium(({items}) => {
  return (
    <div style={[bannerStyle.banner, {gridTemplateColumns: `repeat(${items.length}, 1fr)`}]}>
      {
        items.map(({icon, title, selected}, i) =>
          <BannerItem key={`bannerItem-${i}`} {...{icon, title, selected}}/>)
      }
    </div>

  )
});