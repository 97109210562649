import React from "react"
import ImageButton from "./imageButton/ImageButton"
import {Check, X} from "react-feather"

const Style = {
  container: {
    display: "grid",
    gridGap: 5,
    justifyItems: "center",
    marginBottom: 10
  }
}

const Feedback = ({onRightClicked, onWrongClicked}) =>
  <div style={Style.container}>
    <ImageButton text="Right" icon={<Check/>} onClick={onRightClicked}/>
    <ImageButton text="Wrong" icon={<X/>} onClick={onWrongClicked}/>
  </div>

export default Feedback
