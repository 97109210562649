import React, {useState, useCallback} from "react"
import {identityEndpoint, useApiKey} from "AppConfig";
import {get} from "lib/get";
import {useUpload} from "hooks/useServerCall";
import MobileLayout from "components/layout/MobileLayout";
import FlexBox from "components/layout/FlexBox";
import CaptureImage from "mobile/pages/identity/components/CaptureImage";
import Confirmation from "../components/Confirmation";
import Header from "../components/Header";
import ErrorHandler from "../components/ErrorHandler";
import Loading from "mobile/pages/identity/components/Loading";
import SelfieSample from "./selfie.png";
import {filterErrorCodes, filterFastForwardErrors} from "pages/identity/verification/ErrorMapper";

const SelfieContainer = ({history, location}) => {
  const [fileErrors, setFileErrors] = useState(undefined)
  const apiKey = useApiKey();
  const identityVerificationId = get(["state", "data", "identityVerificationId"], location, undefined)
  const authToken = get(["state", "authToken"], location, undefined);
  const url = `${identityEndpoint}/identityVerification/sync/${identityVerificationId}/selfie`;
  const [data, loading, error, start, clearError, percentCompleted, failedAttempts] = useUpload({authToken, apiKey, url});
  const responseErrorCodes = get(["response", "data", "selfie", "errorCodes"], error, [])
  const errorCodes = filterErrorCodes(responseErrorCodes) // errors that must be displayed to the user
  const fastForwardErrorCodes = filterFastForwardErrors(responseErrorCodes) // errors that continue to the next step
  const maxFailedAttemptsReached = failedAttempts >= 3

  if (fileErrors) {
    errorCodes.push(fileErrors)
  }

  if (error && errorCodes.length === 0) {
    // there was a general error
    errorCodes.push(error.message)
  }

  const resetErrors = useCallback(() => {
    clearError()
    setFileErrors(undefined)
  }, [clearError, setFileErrors])

  return <SelfieRouter {...{
    history, authToken, identityVerificationId, data, loading, errorCodes, start, resetErrors,
    percentCompleted, maxFailedAttemptsReached, fastForwardErrorCodes, setFileErrors
  }}/>
}

export default SelfieContainer

const SelfieRouter = ({
                        history, authToken, identityVerificationId, data, loading, errorCodes = [], start, resetErrors,
                        percentCompleted, maxFailedAttemptsReached, fastForwardErrorCodes = [], setFileErrors
                      }) => {
  let view = null
  if (!authToken || !identityVerificationId) {
    view = <InvalidState message="AuthToken and identityVerificationId is required"/>
  } else {
    if (loading) {
      const loadingMessage = percentCompleted < 100 ? "Uploading..." : "Extracting selfie data...";
      view = <Loading {...{loadingMessage}}/>
    } else if (errorCodes && errorCodes.length > 0 && !maxFailedAttemptsReached) {
      view = <ErrorHandler {...{resetErrors, errorCodes}}/>
    } else if (!data && !maxFailedAttemptsReached && fastForwardErrorCodes.length === 0) {
      return <CaptureSelfie  {...{start, setFileErrors}}/>
    } else {
      const message = fastForwardErrorCodes.length > 0 || maxFailedAttemptsReached
        ? <span>Thank you, let's continue.</span>
        : <span>Thanks, looking good!</span>
      view = <Confirmation message={message}
                           done={() => history.push("/idv/pop", {authToken, identityVerificationId})}/>
    }
  }

  return (
    <MobileLayout header={<Header title="Selfie" step={2}/>}>
      {view}
    </MobileLayout>
  )
}

export const SelfieDemo = () => {
  return (
    <FlexBox column marginBottom>
      {/*
      <FlexBox hCenter>
        <h1>Invalid state</h1>
      </FlexBox>
      <SelfieRouter/>
      <FlexBox hCenter>
        <h1>Loading with different message</h1>
      </FlexBox>
      <SelfieRouter authToken={"a-token"} identityVerificationId={"some-id"} loading={true} percentCompleted={1}/>
      <SelfieRouter authToken={"a-token"} identityVerificationId={"some-id"} loading={true} percentCompleted={100}/>
      <FlexBox hCenter>
        <h1>Test error handling</h1>
      </FlexBox>
      <SelfieRouter authToken={"a-token"} identityVerificationId={"some-id"} error={{}}/>
      <SelfieRouter authToken={"a-token"} identityVerificationId={"some-id"} error={{response: {data: {selfie: {errorCodes: ["blurred-image"]}}}}}/>
      <SelfieRouter authToken={"a-token"} identityVerificationId={"some-id"} error={{response: {data: {selfie: {errorCodes: ["size-limit-exceeded"]}}}}}
                      clearError={() => console.log("Retrying...")}/>
*/}
      <FlexBox hCenter>
        <h1>Capture Selfie</h1>
      </FlexBox>
      <SelfieRouter authToken={"a-token"} identityVerificationId={"some-id"}/>
      {/*
      <FlexBox hCenter>
        <h1>Confirmation</h1>
      </FlexBox>
      <SelfieRouter authToken={"a-token"} identityVerificationId={"some-id"} data={{}}/>
*/}
    </FlexBox>
  )
}

const InvalidState = ({message}) => {
  return (
    <FlexBox style={{textAlign: "center"}} margin centered flexGrow>
      {message}
    </FlexBox>
  )
}

const CaptureSelfie = ({start, setFileErrors}) => {
  return (
    <MobileLayout>
      <FlexBox column flexGrow vCenter>
        <h1>Selfie</h1>
        <h6>Step 2 of 3</h6>
        <StockImage/>
        <Instructions/>
        <FlexBox>
          <CaptureImage onChange={file => {
            const data = new FormData();
            data.append("file", file);
            start(data)
          }} onError={setFileErrors}/>
        </FlexBox>
      </FlexBox>
    </MobileLayout>
  )
}

const StockImage = () => {
  return (
    <FlexBox marginBottom marginTop>
      <FlexBox style={{minWidth: 125, minHeight: 75}} centered>
        <img src={SelfieSample} width={125} alt="An example of a selfie"/>
      </FlexBox>
    </FlexBox>
  )
}

const Instructions = () => {
  return (
    <FlexBox hMargin column>
      <p><b>Please take a selfie with a neutral expression</b></p>
      <h4>Important Checks</h4>
      <ul>
        <li>Have a neutral expression - no smiling!</li>
        <li>The selfie is in focus (hold the camera steady and if appropriate allow it to auto-focus)</li>
        <li>Your face isn’t in dark shadows in front of a bright background</li>
        <li>Your face is the only one in the photo (no photos or another people in the background)</li>
        <li>Finally, take off your glasses</li>
      </ul>
    </FlexBox>
  )
}
