import React, {useRef} from "react";
import * as d3 from "d3";

import Bars from "./Bars";
import XAxis from "./XAxis";
import YAxis from "./YAxis";

const useScales = (data, widthLessMargin, heightLessMargin) => {
  const xScale = useRef(null);
  const yScale = useRef(null);
  
  let max = d3.max(data, d => Math.max(d.credit, d.debit));
  max = max + (max * 0.1); // max + 10% so that we pad the yAxis by 10%
  yScale.current = d3.scaleLinear()
    .domain([0, max])
    .range([heightLessMargin, 0]);

  xScale.current = d3.scaleTime()
    .domain([
      d3.min(data, ({key}) => key),
      d3.timeDay.offset(d3.max(data, ({key}) => key), 1)])
    .range([0, widthLessMargin]);

  return [xScale.current, yScale.current]
};

export const TimeChart = props => {
  const {width, height, margin, data, setItemSelected} = props;
  let widthLessMargin = width - margin.left - margin.right;
  let heightLessMargin = height - margin.top - margin.bottom;
  const [xScale, yScale] = useScales(data, widthLessMargin, heightLessMargin);

  return (
    <svg width={width} height={height}>
      <g transform={`translate(${margin.left}, ${margin.top})`}>
        <rect style={{fill: "rgba(0, 0, 0, 0.05)"}}
              width={widthLessMargin}
              height={heightLessMargin}/>
        <Bars {...{margin, widthLessMargin, heightLessMargin, xScale, yScale, data, setItemSelected}}/>
        <XAxis {...{heightLessMargin, xScale}}/>
        <YAxis {...{yScale}}/>
      </g>
    </svg>
  )
};