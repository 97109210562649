import React from "react";
import Placeholder from "components/Placeholder";
import useTransactionSummaryReducer from "hooks/useTransactionSummaryReducer";
import TitleValueSummaryCard from "components/cards/TitleValueSummaryCard";
import {useBankStatementContext} from "../../bankstatement/BankStatementContext";

const Style = {
  container: {
    display: "grid",
    gridGap: 10,
    justifyItems: "center",
    aliItems: "start",
    gridTemplateRows: "repeat(3, auto)",
    gridTemplateColumns: "auto auto auto",
  },
};

const Summary = ({statementStartDate,
                   statementEndDate,
                   numberOfDebits,
                   numberOfCredits,
                   creditAmount,
                   debitAmount,
                   duration,
                   numberOfTransactions}) => {
  return (
    <div style={Style.container}>
      <TitleValueSummaryCard title="Statement start date" value={statementStartDate}/>
      <TitleValueSummaryCard title="Statement end date" value={statementEndDate}/>
      <TitleValueSummaryCard title="Duration (days)" value={duration} centered/>
      <TitleValueSummaryCard title="Total credit amount" value={creditAmount} credit/>
      <TitleValueSummaryCard title="Number of credits" value={numberOfCredits} credit/>

      <TitleValueSummaryCard title="Number of transactions" value={numberOfTransactions} centered/>
      <TitleValueSummaryCard title="Total debit amount" value={debitAmount} debit/>
      <TitleValueSummaryCard title="Number of debits" value={numberOfDebits} debit/>
    </div>
  )
};

const SummaryContainer = (props) => {
  const {startBatch} = useBankStatementContext();
  const {transactions} = props.extractedData || startBatch.data.extractedData;
  const data = useTransactionSummaryReducer(transactions);
  return data ? <Summary {...data} /> : <Placeholder/>
}

export default SummaryContainer
