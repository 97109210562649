import React, {useEffect, useState} from "react";

const ImageFromFile = ({file, alt = "A form of identity"}) => {
  const [imageData, setImageData] = useState(undefined);

  useEffect(() => {
    const reader = new FileReader();
    reader.onload = () => setImageData(reader.result);
    reader.readAsDataURL(file);

  }, [file]);

  return imageData
    ? <img height={500} src={imageData} alt={alt}/>
    : null
};

export default ImageFromFile
