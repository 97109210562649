import FlexBox from "components/layout/FlexBox"
import {theme} from "theme/theme"
import ConnectLogo from "./connect.svg"

const ConnectButton = () => {
  return (
    <a style={{color: theme.fontColor1, textDecoration: "none"}}
       href="https://sprinthive.com/connect/">
      <FlexBox marginBottom column centered
               style={{backgroundColor: theme.backgroundColor3, padding: 10, borderRadius: 20}}>
        <img src={ConnectLogo} height={30} alt="Contact us logo"/>
        <FlexBox style={{fontSize: 10, fontWeight: 600, color: theme.fontColor1, marginTop: 5}}>CONNECT</FlexBox>
      </FlexBox>
    </a>
  )
}

export default ConnectButton
