import MainLayout from "../layout/MainLayout";
import HSpinner from "../spinner/HSpinner";
import React from "react";
import {theme} from "theme/theme";

const Style = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    color: theme.fontColor4,
  },
  message: {
    fontSize: 18,
    fontWeight: 400,
    marginTop: 10,
  }
};


export const Loading = ({noLayout, message = "Loading one moment please..."}) =>
  noLayout
    ? <LoadingNoLayout {...{message}}/>
    : <LoadingWithLayout {...{message}}/>;

export const LoadingWithLayout = ({message = "Loading one moment please..."}) =>
  <MainLayout>
    <div style={Style.container}>
      <HSpinner/>
      <div style={Style.message}>{message}</div>
    </div>
  </MainLayout>;

export const LoadingNoLayout = ({message = "Loading one moment please..."}) =>
  <div style={Style.container}>
    <HSpinner/>
    <div style={Style.message}>{message}</div>
  </div>;
