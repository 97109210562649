import React, {createContext, useContext, useEffect} from "react"
import {useGet} from "hooks/useServerCall"
import {Loading} from "components/loading/Loading"
import {ErrorPage} from "components/error/ErrorPage"

const identityEndpoint = process.env.REACT_APP_IDENTITY_ENDPOINT || `${window.location.origin}/idv/v2`
const incomeVerificationEndpoint = process.env.REACT_APP_INCOME_VERIFICATION_ENDPOINT || `${window.location.origin}/inv`
const socketIOEndpoint = process.env.REACT_APP_SOCKET_IO_ENDPOINT || `${window.location.origin}`
const feedbackEndpoint = process.env.REACT_APP_FEEDBACK_ENDPOINT || `${window.location.origin}/api/feedback`

console.info("AppConfig")
console.info("identityEndpoint", identityEndpoint)
console.info("incomeVerificationEndpoint", incomeVerificationEndpoint)
console.info("socketIOEndpoint", socketIOEndpoint)
console.info("feedbackEndpoint", feedbackEndpoint)

export {identityEndpoint, incomeVerificationEndpoint, socketIOEndpoint, feedbackEndpoint}

export const AppConfigContext = createContext({})
export const useAppConfigContext = () => useContext(AppConfigContext)

export const useApiKey = () => {
  const {apiKey} = useAppConfigContext()
  return apiKey
}

const useLoadApiKey = () => {
  const [data, loading, error, call] = useGet({url: `${window.location.origin}/demo/api/config`})
  useEffect(() => {
    call()
  }, [call])

  return [data, loading, error]
}

const AppConfig = ({children}) => {
  const [data, loading, error] = useLoadApiKey()
  const {apiKey} = data || {}

  if (error) {
    return <ErrorPage message="Error loading application config"/>
  } else {
    return (
      <AppConfigContext.Provider value={{apiKey}} >
        { loading ? <Loading/> : children}
      </AppConfigContext.Provider>
    )
  }
}

export default AppConfig
