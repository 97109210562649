import React from "react"
import FlexBox from "components/layout/FlexBox"
import {IdentityDocumentDemo} from "./idDoc/IdentityDocument"
import {SelfieDemo} from "./selfie/Selfie"
import {PopDemo} from "./pop/Pop"
import {ResultsDemo} from "./results/Results"


const InvDemoV3 = () => {
  return (
    <FlexBox column>
      <IdentityDocumentDemo/>
      <SelfieDemo/>
      <PopDemo/>
      <ResultsDemo/>
    </FlexBox>
  )
}

export default InvDemoV3
