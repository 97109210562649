import React, {useEffect, useState, useRef} from "react"
import PDFObject from "pdfobject"

const ImageFromPdf = ({file}) => {
  const pdfRef = useRef(undefined);
  const [imageData, setImageData] = useState(undefined);
  useEffect(() => {
    const reader = new FileReader();
    reader.onload = () => setImageData(reader.result);
    reader.readAsDataURL(file);

  }, [file]);

  if (imageData) {
    PDFObject.embed(imageData, pdfRef.current, {width: "600px", height: "500px"});
  }

  return <div ref={pdfRef}/>
}

const ImageFromPdfContainer = (props) => {
  if (PDFObject.supportsPDFs) {
    return <ImageFromPdf {...props}/>
  } else {
    console.warn("Your browser does not support the pdf viewer plugin, using fallback");
    return props.children
  }
}

export default ImageFromPdfContainer
