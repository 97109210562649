import React from "react";
import Radium from "radium";
import Spinner from "../spinner/HSpinner";
import {theme} from "../../theme/theme";
import {Power} from "react-feather";

const buttonStyle = {
  button: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "40px auto",
    color: theme.linkColor,
    fontWeight: 600,
    backgroundColor: theme.backgroundColor5,
    minWidth: 200,
    minHeight: 40,
    height: 40,
    paddingLeft: 10,
    borderRadius: 10,
    cursor: "pointer",
    fontSize: 16,
    userSelect: "none",
  },
  icon: {
    fontSize: 14,
    color: "#FFF",
    justifySelf: "end",
  },
  text: {
    justifySelf: "start",
  },
  disabled: {
    backgroundColor: theme.backgroundColor1,
    color: theme.fontColor4,
    cursor: "not-allowed"
  }
};

const defaultOnClickHandler = () => console.log("clicked");

const IconOrSpinner = ({showSpinner, icon}) =>
  !showSpinner ? icon
    : <Spinner style={buttonStyle.icon} dotStyle={{backgroundColor: "#fff"}}/>;

export default Radium(({
                         text = "Click me",
                         onClick = defaultOnClickHandler,
                         icon = <Power/>,
                         showSpinner = false,
                         disabled = false,
                         style
                       }) =>
  <div style={[buttonStyle.button, (disabled || showSpinner) && buttonStyle.disabled, style]}
       onClick={!disabled && !showSpinner ? onClick : null}>
    <IconOrSpinner {...{icon, showSpinner}}/>
    <div style={buttonStyle.text}>{text}</div>
  </div>
)