import React from "react"
import ImageTitleCard from "components/cards/ImageTitleCard"
import {List, ListItem} from "components/list/List"
import errorMapper from "../verification/ErrorMapper"

const Style = {
  pop: {
    gridArea: "pop",
    display: "grid",
    gridColumnGap: 10,
    gridRowGap: 20,
    alignItems: "start",
    justifyItems: "start",
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
  },
}

const PopView = ({status, providedImageUrl, proofOfPresenceRequirement, errorCodes}) => {
  const title = "Proof of presence";
  if (status === "not-started") {
    return (
      <div style={{gridArea: "pop"}}>
        <div>{title} has not been started</div>
      </div>
    )
  } else if (status === "failed") {
    return (
      <div style={Style.pop}>
        {  providedImageUrl && <ImageTitleCard imageUrl={providedImageUrl} title={title} width={200}/>}
        <List style={{marginTop: 20}}>
          <ListItem>{title} has been unsuccessfully captured</ListItem>
          {proofOfPresenceRequirement && <ListItem>The {title.toLowerCase()} requirement is {proofOfPresenceRequirement}</ListItem>}
          {
            errorCodes && errorCodes.map(errorCode => <ListItem key={errorCode}>{errorMapper(errorCode)}</ListItem>)
          }
        </List>
      </div>
    )
  } else if (status === "captured") {
    return (
      <div style={Style.pop}>
        {  providedImageUrl && <ImageTitleCard imageUrl={providedImageUrl} title={title} width={200}/>}
        <List style={{marginTop: 20}}>
          <ListItem>{title} has been successfully captured</ListItem>
          {proofOfPresenceRequirement && <ListItem>The {title.toLowerCase()} requirement is {proofOfPresenceRequirement}</ListItem>}
        </List>
      </div>
    )
  }
}

export default PopView
