import React, {useEffect, useState, useCallback} from "react";
import {Square, CheckSquare} from "react-feather";

const Style = {
  container: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "20px auto",
    gridTemplateAreas: `
      "checkbox label"
    `,
    gridGap: 10,
    cursor: "pointer",
  },
  checkBox: {
    gridArea: "checkbox",
  },
  label: {
    gridArea: "label",
    paddingBottom: 4,
    textAlign: "left",
  }
};

const CheckBox = ({onChanged, label}) => {
  const [checked, setChecked] = useState(false);
  const toggle = useCallback(() => setChecked(c => !c), [setChecked]);

  useEffect(() => {
    onChanged && onChanged(checked)
  }, [onChanged, checked]);

  const checkbox = checked ? <CheckSquare /> : <Square/>;
  return (
    <div style={Style.container} >
      <div style={Style.checkBox} onClick={toggle}>{checkbox}</div>
      <div style={Style.label}>{label}</div>
    </div>
  )
};

export default CheckBox
