import React from "react"
import "./HSpinner.css"
import {theme} from "theme/theme"

const HSpinnerStyle = {
  elipsis: {
    backgroundColor: theme.linkColor
  }
}

const HSpinner = ({style, dotStyle}) => {
  const mergedDotStyle = {...HSpinnerStyle.elipsis, ...dotStyle}
  return (
    <div className="lds-ellipsis" style={style}>
      <div style={mergedDotStyle}/>
      <div style={mergedDotStyle}/>
      <div style={mergedDotStyle}/>
      <div style={mergedDotStyle}/>
    </div>
  )
}

export default HSpinner
