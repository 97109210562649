import React from "react";
import {User, PieChart, UserCheck, UploadCloud} from "react-feather";
import Banner from "../../../components/banner/Banner";

const IdentityBanner = ({selectedIndex=0}) =>
  <Banner items={[
    {icon: <UploadCloud/>,  title: "Proof of identity", selected: selectedIndex === 0},
    {icon: <User/>,  title: "Self headshot", selected: selectedIndex === 1},
    {icon: <UserCheck/>,  title: "Proof of presence", selected: selectedIndex === 2},
    {icon: <PieChart/>,  title: "View results", selected: selectedIndex === 3},
  ]}/>

export default IdentityBanner
