import React from "react";
import Radium from "radium";
import Banner from "./Banner";
import BannerLayout from "components/layout/BannerLayout";
import ImageTitleCard from "components/cards/ImageTitleCard";
import {theme} from "theme/theme";
import TitleValueCard from "components/cards/TitleValueCard";
import Button from "components/button/Button";

const Style = {
  container: {
    display: "grid",
    gridGap: 20,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 30,
    gridTemplateColumns: "200px 1fr 250px",
    gridTemplateRows: "repeat(2, auto) 1fr",
    gridTemplateAreas: `
      "images  content cta"
      "images  selfie  cta"
      "images  pop     cta"
    `,
  },
  content: {
    gridArea: "content",
    display: "grid",
    gridColumnGap: 10,
    gridRowGap: 20,
    alignItems: "start",
    justifyItems: "start",
    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
  },
  images: {
    gridArea: "images",
    display: "grid",
    gridGap: 20,
    paddingTop: 10,
    paddingBottom: 10,
    justifyItems: "center",
    alignItems: "start",
    backgroundColor: theme.backgroundColor3,
  },
  selfie: {
    gridArea: "selfie",
    display: "grid",
    gridColumnGap: 10,
    gridRowGap: 20,
    alignItems: "start",
    justifyItems: "start",
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
  },
  pop: {
    gridArea: "pop",
    display: "grid",
    gridColumnGap: 10,
    gridRowGap: 20,
    alignItems: "start",
    justifyItems: "start",
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
  },
  cta: {
    gridArea: "cta",
    display: "grid",
    alignItems: "start",
    gridRowGap: 10,
    gridTemplateRows: "auto auto 1fr",
  },
};

export const Content = ({data}) => {
  return (
    <div style={Style.content}>
      {
        data.map((d,i) => <TitleValueCard key={`${i}-identity-data`} {...d}/>)
      }
    </div>
  )
};

export const Selfie = ({imageUrl}) => {
  return (
    <div style={Style.selfie}>
      <ImageTitleCard imageUrl={imageUrl} title="Self headshot" width={200}/>
      <div>Self headshot is a match</div>
    </div>
  )
};

export const ProofOfPresence = ({imageUrl}) => {
  return (
    <div style={Style.pop}>
      <ImageTitleCard imageUrl={imageUrl} title="Proof of presence" width={200}/>
      <div>Proof of presence is a match</div>
    </div>
  )
};

const Images = Radium(({titleUrls}) => {
  return (
    <div style={[Style.images, {gridTemplateRows: `repeat(${titleUrls.length -1}, auto) 1fr`}]}>
      {
        titleUrls.map((d, i) => <ImageTitleCard key={`${i}-titleImage`} {...d}/>)
      }
    </div>
  )
});

const CallToAction = ({onNextClicked, onRestart}) => {
  return (
    <div style={Style.cta}>
      <div>
        Great, that is the end of identity verification process.
      </div>
      <Button onClick={onRestart}>Start again</Button>
      <Button onClick={onNextClicked}>All done</Button>
    </div>
  )
};

const CaptureProofOfPresenceResults = ({history, location: {state}}) => {
  const onNextClicked = () => {
    history.push("/", state)
  };

  const onRestart = () => {
    history.push("/idv/identityVerification/selectIdentity")
  };

  const {providedImageUrl} = state.data.identityDocument;
  const {identityNumber, firstName, middleNames, lastName, gender, initials, nationality, countryOfBirth, dateOfBirth,
    identityPhotoUrl, passedValidation} = state.data.identityDocument.extractedData;
  const {providedImageUrl: selfieImageUrl} = state.data.selfie;
  const {providedImageUrl: popImageUrl} = state.data.proofOfPresence;

  const passedValidationText = Boolean(passedValidation) ? "true" : "false";
  const extractedData = [
    {title: "Identity number", value: identityNumber},
    {title: "Initials", value: initials},
    {title: "First name", value: firstName},
    {title: "Middle names", value: middleNames},
    {title: "Last name", value: lastName},
    {title: "Gender", value: gender},
    {title: "Date of birth", value: dateOfBirth},
    {title: "Country of birth", value: countryOfBirth},
    {title: "Nationality", value: nationality},
    {title: "Validations passed", value: passedValidationText},
  ];

  const titleUrls = [
    {title: "Original image", imageUrl: providedImageUrl},
    {title: "Extracted image", imageUrl: identityPhotoUrl},
  ];

  return (
    <BannerLayout banner={<Banner selectedIndex={3}/>}>
      <div style={Style.container}>
        <Content {...{data: extractedData}}/>
        <Images {...{titleUrls}}/>
        <Selfie imageUrl={selfieImageUrl}/>
        <ProofOfPresence imageUrl={popImageUrl}/>
        <CallToAction {...{onNextClicked, onRestart}}/>
      </div>
    </BannerLayout>
  )
}

export default CaptureProofOfPresenceResults
