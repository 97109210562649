import React from "react";
import {theme} from "theme/theme";
import Banner from "../../components/banner/Banner";
import BannerLayout from "components/layout/BannerLayout";
import PayslipSummary from "components/createTemplate/PayslipSummary";
import SecureImage from "components/secureImage/SecureImage";
import {usePayslipContext} from "../PayslipContext";
import Feedback from "../../components/Feedback";

const Style = {
  container: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    gridTemplateAreas: `
      "summary    buttons"
      "workspace  workspace"
    `,
  },
  summary: {
    gridArea: "summary",
    padding: 20,
    backgroundColor: theme.backgroundColor3,
    maxHeight: 150
  },
  buttons: {
    gridArea: "buttons",
    display: "grid",
    gridGap: 10,
    gridTemplateColumns: "auto",
    gridTemplateRows: "auto auto 1fr",
    paddingTop: 20,
    paddingRight: 20,
    paddingBottom: 20,
    backgroundColor: theme.backgroundColor3,
  },
  workspace: {
    gridArea: "workspace",
    padding: 40,
    display: "grid",
    gridTemplateColumns: "1fr",
    justifyItems: "center",
  },
};

const ViewResults = (props) => {
  const {data, authToken, reset} = props;
  const {metaData = {}, extractedData = {}, pageImageUrl} = data;

  return (
    <BannerLayout banner={<Banner selectedIndex={2}/>}>
      <div style={Style.container}>
        <div style={Style.summary}>
          <PayslipSummary {...{extractedData, metaData}}/>
        </div>

        <div style={Style.buttons}>
          <Feedback style={{container: {maxHeight: 150}}} reset={reset}/>
        </div>

        <div style={Style.workspace}>
          <SecureImage {...{authToken, imageUrl: pageImageUrl}}/>
        </div>
      </div>
    </BannerLayout>
  )
};

const ViewResultsContainer = () => {
  const {startBatch, onFieldUpdated} = usePayslipContext();
  const {data, authToken, reset} = startBatch;
  return (
    <ViewResults {...{data, authToken, onFieldUpdated, reset}}/>
  )
}

export default ViewResultsContainer
